import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EnvironmentService, LoggingService, SessionService} from '@tymes4-shared';
import { UserSalesChannel } from '@tymes4-shared/lib/models/session/user-sales-channel';
import {AppUser} from 'app/shared/models/app-user';
import { MappingHelper } from '../../../../../../Backoffice/src/app/shared/helpers/mapping-helper';
import {Observable} from 'rxjs';
import { FanManagementUser, UserSalesChannelView } from '../../api';


@Injectable()
export class AuthService {


  constructor(
    private http: HttpClient,
    private logging: LoggingService,
    private environmentService: EnvironmentService,
    private sessionService: SessionService
  ) {
  }

  _loggedInUser: any;

  isLoggedIn(): boolean {
    return this.sessionService.isLoggedIn();
  }

  getToken(): string {
    return this.sessionService.getToken();
  }

  setToken(token: string) {
    this.sessionService.setToken(token);
  }

  setUser(user: FanManagementUser) {
    this.sessionService.setUser(this.MapFanUserToAppUser(user));
  }

  authenticateImpersonation(id, token): any {
    throw 'This is no longer in use';
    const apiUrl = this.environmentService.loadConfigurations().apiURL;
    const data = `id=${id}&accesstoken=${token}&grant_type=impersonation`;

    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'no-auth': 'true'
    });

    const url = `${apiUrl}/token`;
    return this.http.post(url, data, {headers: reqHeader});
  }

  getUser(): Observable<AppUser> {
    throw 'This is no longer in use!';
    const apiUrl = this.environmentService.loadConfigurations().apiURL;
    const url = `${apiUrl}/api/user/current`;
    return this.http.get(url) as Observable<AppUser>;
  }

  private MapFanUserToAppUser(user : FanManagementUser) : AppUser {
    let appUser : AppUser = {
        Id: user.Id,
        Firstname: user.Firstname,
        Middlename: user.Middlename,
        Lastname: user.Lastname,
        Fullname: user.Fullname,
        MenuItems: user.MenuItems.map(m => MappingHelper.MapMenuItemViewToMenuItem(m)),
        Channels: user.Channels.map(c => this.MapUserSalesChannelViewToUserSalesChannel(c)),
        IsArchiveMode: false, //archive mode is not yet implemented in fan management, therefore, this can be false by default
        ProfileImage: user.ProfileImage,
        LanguageCode: user.LanguageCode,
        ShowGoToProfile: user.ShowGoToProfile
    };

    return appUser;
  }

  private MapUserSalesChannelViewToUserSalesChannel(salesChannelView : UserSalesChannelView) : UserSalesChannel {
    const salesChannel : UserSalesChannel = {
        HexColor: salesChannelView.HexColor,
        IsPrimary: salesChannelView.IsPrimary,
        Name: salesChannelView.Name,
        SalesChannelId: salesChannelView.SalesChannelId,
        SalesGroupId: salesChannelView.SalesGroupId,
        UserId: salesChannelView.UserId
    };
    return salesChannel;
  }
}
