
  <div class="row modal-bar">
    <div class="col-sm-6">
        <h5 class="modal-title">{{ passedData.title }}</h5>
    </div>
    <div class="col-sm-6">
        <div class="modal-actions">
            <div class="modal-close">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span (click)="dialogRef.close(false);">×</span>
                </button>
            </div>
        </div>
    </div>
  </div>

  <form [formGroup]="form" class="form-content">
    <div class="section-wrapper">
        <div class="form-layout row">
            <div class="col-lg-12">
                <div class="form-group">
                <label class="form-control-label">{{ 'FAN.ADDRESELLERCONTACT.FORM.ACCOUNT.LABEL' | translate }}: <span class="tx-danger" *ngIf="isRequiredField('ContactId')">*</span></label>

                <ng-select [items]="contacts | async" bindLabel="Name" bindValue="Id" formControlName="ContactId" placeholder="Account" [typeahead]="contactsInput$" loadingText="Laden..."
                            [clearable]="false" notFoundText="{{ 'FAN.ADDRESELLERCONTACT.FORM.ACCOUNT.NOTFOUND' | translate }}" appendTo="body" [loading]="contactsLoading" [virtualScroll]="true" typeToSearchText="{{ 'FAN.ADDRESELLERCONTACT.FORM.ACCOUNT.TYPETOSEARCH' | translate }}">
                    <ng-template ng-label-tmp ng-option-tmp let-item="item">
                      {{ getItemDisplayText(item) }}
                    </ng-template>
                </ng-select>

                <t4-display-validation controlName="ContactId" [form]="form" [messages]="validationMessages"></t4-display-validation>
              </div>
            </div>
        </div>

       <!-- Buttons -->
      <div class="row">

        <div class="col-6">
          <button (click)="submit()" [disabled]="!IsValidAndDirty()" class="btn btn-block btn-primary bd-0">
            <i class="far fa-save"></i>
            <span>{{ 'BTN.SAVE' | translate }}</span>
          </button>
        </div>
        <div class="col-6">
          <button (click)="dialogRef.close(false);" class="btn btn-block btn-secondary bd-0">
            <i class="fas fa-ban"></i>
            <span>{{ 'BTN.CANCEL' | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </form> 
