import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import {Tab} from '../../models/tabs/tab';
import {Observable} from 'rxjs';

@Component({
  selector: 't4-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {

  constructor() {
  }

  tabs: Tab[] = [];

  @Output() selected = new EventEmitter();
  @Input() beforeSelectTabCheck: (Tab) => Observable<boolean>;

  addTab(tab: Tab, pos: string) {
    if (!tab.isPreSelected && !this.tabs.length) {
      tab.selected = true;
    }

    if (pos !== undefined)
      this.tabs.splice(+pos, 0, tab);
    else
      this.tabs.push(tab);

    if (tab.isPreSelected && tab.selected) {
      this.selectTab(tab);
    }
  }

  public selectTab(tab: Tab) {
    if (this.beforeSelectTabCheck) {
      this.beforeSelectTabCheck(tab).subscribe((result) => {
        if (result) {
          this.tabs.map((_tab) => {
            _tab.selected = false;
          });
          tab.selected = true;
          this.selected.emit({selectedTab: tab});
        }
      });
    } else {
      this.tabs.map((_tab) => {
        _tab.selected = false;
      });
      tab.selected = true;
      this.selected.emit({selectedTab: tab});
    }
  }

  ngOnInit() {
  }

}
