<mat-dialog-content>
<div class="row modal-bar">
    <div class="col-sm-6">
        <h5 class="modal-title">{{ passedData.title | translate }}</h5>
    </div>
    <div class="col-sm-6">
        <div class="modal-actions">
            <div class="modal-close">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span (click)="dialogRef.close(false);">×</span>
                </button>
            </div>
        </div>
    </div>
  </div>
  
  <form [formGroup]="form" class="form-content">
    <t4-page-info *ngIf="readonly" info="FAN.EDITEVENTPRODUCT.FORM.DISABLEDMSG"></t4-page-info>
    <t4-page-info *ngIf="!readonly && (product.FantastixOnSaleFrom != null || product.FantastixOnSaleTill != null)" info="FAN.EDITEVENTPRODUCT.FORM.ONSALESETTINGOVERWRITTENMSG" [parameters]="{ onSaleFrom: product.FantastixOnSaleFrom != null ? datePipe.transform(product.FantastixOnSaleFrom, 'dd/MM/yyyy HH:mm') : '-', onSaleTill: product.FantastixOnSaleTill != null ? datePipe.transform(product.FantastixOnSaleTill, 'dd/MM/yyyy HH:mm') : '-' }"></t4-page-info>

    <div class="section-wrapper">
      <div class="form-layout">
        <div class="row mg-b-25">
            <div class="col-lg-12">
                <div class="form-group">
                  <label class="form-control-label">{{'FAN.EDITEVENTPRODUCT.FORM.NAME.LABEL' | translate}}: <span class="tx-danger" *ngIf="isRequiredField('CrossSellProductName')">*</span></label>
                  <input class="form-control" name="CrossSellProductName" formControlName="CrossSellProductName" >
                  <t4-display-validation controlName="CrossSellProductName" [form]="form" [messages]="validationMessages"></t4-display-validation>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group">
                    <label class="form-control-label">{{'FAN.EDITEVENTPRODUCT.FORM.STOCKCOUNT.LABEL' | translate}}: <span class="tx-danger" *ngIf="isRequiredField('CurrentStockCount')">*</span></label>
                    <input class="form-control" name="CurrentStockCount" formControlName="CurrentStockCount" type="number" [min]="product.SoldCount"> 
                    <t4-display-validation controlName="CurrentStockCount" [form]="form" [messages]="validationMessages"></t4-display-validation>
                  </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group">
                    <label class="form-control-label">{{'FAN.EDITEVENTPRODUCT.FORM.AMOUNTINVAT.LABEL' | translate}}: <span class="tx-danger" *ngIf="isRequiredField('AmountInVat')">*</span></label>
                    <input class="form-control" name="AmountInVat" formControlName="AmountInVat" type="number" currencyformat min="0">
                    <t4-display-validation controlName="AmountInVat" [form]="form" [messages]="validationMessages"></t4-display-validation>
                  </div>
            </div>
            <div class="col-lg-12">
                <div class="form-group">
                    <label class="form-control-label">{{'FAN.EDITEVENTPRODUCT.FORM.DESCRIPTION.LABEL' | translate}}: <span class="tx-danger" *ngIf="isRequiredField('CrossSellProductDescription')">*</span></label>
                    <textarea class="form-control" name="CrossSellProductDescription" formControlName="CrossSellProductDescription"></textarea>
                    <t4-display-validation controlName="CrossSellProductDescription" [form]="form" [messages]="validationMessages"></t4-display-validation>
                  </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <t4-date-time-control formControlName="OnSaleFrom"  [labelDate]="'FAN.EDITEVENTPRODUCT.FORM.ONSALEFROMDATE.LABEL'" [labelTime]="'FAN.EDITEVENTPRODUCT.FORM.ONSALEFROMTIME.LABEL'"></t4-date-time-control>
                <t4-display-validation controlName="OnSaleFrom" [form]="form" [messages]="validationMessages"></t4-display-validation>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <t4-date-time-control formControlName="OnSaleTill"  [labelDate]="'FAN.EDITEVENTPRODUCT.FORM.ONSALETILLDATE.LABEL'" [labelTime]="'FAN.EDITEVENTPRODUCT.FORM.ONSALETILLTIME.LABEL'"></t4-date-time-control>
                <t4-display-validation controlName="OnSaleTill" [form]="form" [messages]="validationMessages"></t4-display-validation>
              </div>
            </div>
        </div>
      </div>
  
      <div class="form-layout-footer mt-5 row" *ngIf="!readonly">
        <div class="col-6">
          <button (click)="submit()" [disabled]="!form.dirty || form.invalid || saving"  class="btn btn-block btn-primary bd-0"> <i class="far fa-save"></i><span>{{'BTN.SAVE' | translate}}</span></button>
        </div>
        <div class="col-6">
          <button (click)="dialogRef.close(false);"  class="btn btn-block btn-secondary bd-0"><i class="fas fa-ban"></i><span>{{'BTN.CANCEL' | translate}}</span></button>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>