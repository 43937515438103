import {Component, EventEmitter, Input, Output} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Customer } from '../../models/customer/customer';

@Component({
  selector: 't4-customer-identification-control',
  templateUrl: 'customer-identification-control.component.html', 
  styleUrls: ['./customer-identification-control.component.scss']
})
export class CustomerIdentificationControlComponent{

  @Input() public isIbaActive: boolean = false;
  @Input() public customer: IdentificationCustomer;
  @Input() public showCustomerNumber: boolean = false;

  constructor(
    private router: Router,
    private dialog: MatDialog,
  ) {
  }

  private customerUrl: string = 'relations/customers/details/';

  ngOnInit(){
  }

  checkInterface(obj: any): obj is Customer {
    for (const prop of Object.keys(obj)) {
      if (!(prop in obj)) {
        return false;
      }
    }
    return true;
  }
  

  getIdentificationStatus(){
    if(this.customer == undefined || this.customer == undefined){
      this.customer = {
        CalculatedName: '',
        IsIdentified: false,
        CustomerNumber: '',
      }
    }
    if(this.customer.IsIdentified){
      return 'fas fa-check-circle text-success pr-2';
    }
    return 'opacity-25 far fa-times-circle text-secondary pr-2';
  }

  getCustomerId(){
    if(this.customer.TicketCustomerId !== undefined){
      return this.customer.TicketCustomerId;
    } 
    return this.customer.CustomerId;
  }

  routeToCustomer(cId){
    let link = this.customerUrl + cId;
    this.dialog.closeAll();
    this.router.navigate([link]);
    return false;
  }

}

export interface IdentificationCustomer {

  /**
   * The formatted name of the contact. Used only for persons.
   */
  CalculatedName: string;

  /**
   * The type of customer (1 for company, 2 for person). Defaults to person.
   */
  CustomerType?: number;

  /**
   * The name of the customer (not the contact) to be shown. Used only for companies.
   */
  CustomerName?: string;

  /**
   * Alias of {@link CustomerName}.
   */
  Name?: string;

  /**
   * Truthy if the customer has been successfully identified; otherwise, falsy.
   */
  IsIdentified?: boolean;

  /**
   * The (formatted) customer number to be shown.
   */
  CustomerNumber?: string;

  /**
   * The numeric id of the customer.
   */
  CustomerId?: number | string;

  /**
   * Alias of {@link CustomerId}.
   */
  TicketCustomerId?: number | string;
}
