import * as moment from 'moment-timezone';
import { Pipe, PipeTransform } from '@angular/core';
import { SessionService } from '../services/session.service';

@Pipe({
  name: 'localDateLong'
})
export class localDateLongPipe implements PipeTransform {

  constructor(private sessionService: SessionService) {}
  
  transform(value: moment.MomentInput, compensateTimeZone: boolean = false): string {
    
    if (value === null || value === undefined || value === '') return null;
    
    var language = this.sessionService.getLanguage();

    if (language == null)
      console.error('localDate cannot be used when language == null.');

    let formatDate:string = "DD MMMM yyyy";
 
    return moment.utc(value)
      .tz(compensateTimeZone ? language.TimeZone : 'UTC')
      .locale(language.Locale)
      .format(formatDate);
  }
}