import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ProductTypeEnum } from '../../../enums/product-type.enum';

@Component({
  selector: 't4-order-header',
  templateUrl: './order-header.component.html',
  styleUrls: ['./order-header.component.scss']
})
export class OrderHeaderComponent implements OnInit {
  

  constructor(private router: Router, 
    private dialog: MatDialog, 
    private translate: TranslateService) { }

  orderDetails = null;
  totalTickets = null;


  @Input()
  set details(d) {
    this.orderDetails = d;
    this.calculateTicketCount(this.orderDetails);
  }
  @Input() isIbaActive: boolean = false;
  @Input() customerUrl: string = 'relations/customers/details/';
  
  ngOnInit() {
  }

  calculateTicketCount(orderDetails: any) {
    
    this.totalTickets = 0;

    if (!orderDetails || orderDetails == null) return;
    
    var ticketLines;
    if (orderDetails.SecondaryOrderLines != null)
    {
      ticketLines = orderDetails.SecondaryOrderLines.filter(ol => ol.ProductType == ProductTypeEnum.Ticket);      
    }
    else
    {
      ticketLines = orderDetails.OrderLines.filter(ol => ol.ProductType == ProductTypeEnum.Ticket);
    }
    
    this.totalTickets = ticketLines.length;
  }
  
  linkToCustomer(cId) {
    let link = this.customerUrl + cId;
    this.dialog.closeAll();
    this.router.navigate([link]);
    return false;
  }

  getPrimaryPaymentMethod() {
    if (this.orderDetails) {
      if (this.orderDetails.PayByInvoice) {
        return this.translate.instant('T4.ORDERHEADER.PAYMENTMETHOD.INVOICE');
      } else {
        return this.orderDetails.UsedPaymentMethods ? this.orderDetails.UsedPaymentMethods.split(',')[0] : this.translate.instant('T4.ORDERHEADER.PAYMENTMETHOD.DIRECT');
      }
    } else {
      return '';
    }
  }
}
