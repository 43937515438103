import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {PageFooterComponent} from '../components/layout/page-footer/page-footer.component';
import {PageHeaderComponent} from '../components/layout/page-header/page-header.component';
import {PageSidenavComponent} from '../components/layout/page-sidenav/page-sidenav.component';
import {AdminLayoutComponent} from '../layouts/admin-layout/admin-layout.component';
import {ConfirmService} from '../services/confirm.service';
import {SessionService} from '../services/session.service';
import {ConfirmModule} from './confirm.module';
import {InjectComponentModule} from './inject-component.module';
import {PipesModule} from './pipes.module';
import {NgSelectModule} from '@ng-select/ng-select';

@NgModule({
  declarations: [
    AdminLayoutComponent,
    PageHeaderComponent,
    PageFooterComponent,
    PageSidenavComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ConfirmModule,
    TranslateModule,
    PipesModule,
    InjectComponentModule,
    NgSelectModule
  ],
  exports: [],
  providers: [
    TranslateService,
    ConfirmService,
    SessionService
  ],
  entryComponents: [
    AdminLayoutComponent
  ]
})
export class LayoutsModule {
}
