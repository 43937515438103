import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 't4-card',
  templateUrl: 'card.component.html'
})

export class CardComponent implements OnInit {
  @Input() isLoading: boolean = false;
  @Input() cardTitle: string = null;
  @Input() showFooter: boolean = false;
  @Input() class: string = '';
  @Input() bodyClass: string = '';

  constructor() {
  }

  ngOnInit() {
  }

  getCssClasses() {
    
    let result = 'card card-profile ' + this.class;
    return result;
  }

  getBodyCssClasses() {
    let result = 'card-body ' + this.bodyClass;
    return result;
  }
}
