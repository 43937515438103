import { Routes } from '@angular/router';
import {AdminLayoutComponent} from '@tymes4-shared';
import {EmptyWithMessageComponent} from './shared/layouts/empty-with-message/empty-with-message.component';
import {AuthGuard} from './shared/services/auth/auth.guard';

export const rootRouterConfig: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard'
  },
  {
    path: 'sessions',
    canActivate: [AuthGuard],
    loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule)
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    component: AdminLayoutComponent,
    loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'order',
    canActivate: [AuthGuard],
    component: AdminLayoutComponent,
    loadChildren: () => import('./views/orders/orders.module').then(m => m.OrdersModule)
  },
  {
    path: 'member',
    canActivate: [AuthGuard],
    component: AdminLayoutComponent,
    loadChildren: () => import('./views/members/members.module').then(m => m.MembersModule)
  },
  {
    path: 'my-fanclub',
    canActivate: [AuthGuard],
    component: AdminLayoutComponent,
    loadChildren: () => import('./views/my-fanclub/my-fanclub.module').then(m => m.MyFanclubModule)
  },
  {
    path: 'product',
    canActivate: [AuthGuard],
    component: AdminLayoutComponent,
    loadChildren: () => import('./views/products/products.module').then(m => m.ProductsModule)
  },
  {
    path: 'error',
    children: [
      {
        path: 'invalid-login',
        component: EmptyWithMessageComponent,
        data: {title: 'ERROR.INVALID-LOGIN.TITLE', message: 'ERROR.INVALID-LOGIN.MESSAGE'}
      },
      {
        path: 'invalid-session',
        component: EmptyWithMessageComponent,
        data: {title: 'ERROR.INVALID-SESSION.TITLE', message: 'ERROR.INVALID-SESSION.MESSAGE'}
      },
      {
        path: '404',
        component: EmptyWithMessageComponent,
        data: {title: 'ERROR.PAGE-NOT-FOUND.TITLE', message: 'ERROR.PAGE-NOT-FOUND.MESSAGE'}
      }
    ]
  },
  {
    path: 'account',
    loadChildren: () => import('./views/account/account.module').then(m => m.AccountModule)
  },
  {
    path: '**',
    redirectTo: 'error/404'
  }
];
