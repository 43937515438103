
      <script>
        NProgress.configure({ showSpinner: false });
        NProgress.start();
      </script>

      <div id="toaster"></div>


      <!-- ====================================
      ——— WRAPPER
      ===================================== -->
      <div class="wrapper" [class.archiving]="isArchiveMode">


          <t4-page-sidenav></t4-page-sidenav>


          
          <!-- ====================================
          ——— PAGE WRAPPER
          ===================================== -->
          <div class="page-wrapper" id="main-scrollbar">

            <!-- Header -->
          <t4-page-header></t4-page-header>

            <div class="view-loader" *ngIf="isModuleLoading">
              <div class="spinner">
                <div class="double-bounce1 bounce-accent"></div>
                <div class="double-bounce2 bounce-primary"></div>
              </div>
            </div>



          <!-- ====================================
          ——— CONTENT WRAPPER
          ===================================== -->
          <div class="content-wrapper">
            <div class="content">



              <router-outlet></router-outlet>

            </div>

          </div>

  <!-- Footer -->
  <t4-page-footer></t4-page-footer>

    </div>
  </div> <!-- End Wrapper -->
