import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {HttpLoaderService, ListViewSelectionMode } from '@tymes4-shared';
import { CrossSellProductService, FanCrossSellProductVariantStockMutationView } from '../../api';

@Component({
    selector: 'fan-event-product-stockmutations',
    templateUrl: './event-product-stockmutations.component.html',
    providers: [HttpLoaderService]
  })

  export class EventProductStockMutationsComponent implements OnInit {

    public productVariantId: number = 0;
    public stockMutations: Array<FanCrossSellProductVariantStockMutationView> = null;
    
    constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
            public dialogRef: MatDialogRef<EventProductStockMutationsComponent>,
            private productService : CrossSellProductService,
            private loader: HttpLoaderService) {}

    public listOptions = {
        search: false,
        showBarcodeSearch: false,
        pageSize: 12,
        noItemsText: 'FAN.EVENTPRODUCTSTOCKMUTATIONS.NOITEMSFOUND',
        selectionMode: ListViewSelectionMode.None,
        disableSort: true,
        headers: [
          {sortField: null, label: 'FAN.EVENTPRODUCTSTOCKMUTATIONS.HEADER.DATE'},
          {sortField: null ,label: 'FAN.EVENTPRODUCTSTOCKMUTATIONS.HEADER.MUTATION'},
          {sortField: null, label: 'FAN.EVENTPRODUCTSTOCKMUTATIONS.HEADER.MUTATEDBY'},
        ]
    };

    ngOnInit()
    {
        this.productVariantId = this.passedData.productVariantId;

        this.productService.getStockMutations(this.productVariantId).subscribe((resp: Array<FanCrossSellProductVariantStockMutationView>) => {
          this.stockMutations = resp;
      });
    }

  }
