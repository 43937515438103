import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 't4-confirm',
  templateUrl: './confirm.component.html'
})
export class ConfirmComponent {

  public okBtnText = 'BTN.OK';
  public cancelBtnText = 'BTN.CANCEL';

  constructor(
    public dialogRef: MatDialogRef<ConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data.cancelonly === undefined) {
      data.cancelonly = false;
    }
    if (data.okonly === undefined) {
      data.okonly = false;
    }

    if (data.okBtnText) {
      this.okBtnText = data.okBtnText;
    }
    if (data.cancelBtnText) {
      this.cancelBtnText = data.cancelBtnText;
    }
  }
}
