<div [class]="getCssClasses()">

  <div class="card-body-height">

    <div class="card-header" *ngIf="cardTitle !== null">
      <h2>{{cardTitle}}</h2>
    </div>

    <div [class]="getBodyCssClasses()" [style.textAlign]="'left'">
      <ng-content select="[data-body]" *ngIf="!isLoading; else cardLoader"></ng-content>
    </div>


  </div>

  <div class="card-footer" *ngIf="showFooter" style="min-height: 24px;">
    <div><ng-content select="[data-footer-left]"></ng-content></div>
    <div><ng-content select="[data-footer-right]" fxLayoutAlign="flex-end"></ng-content></div>
  </div>

</div>
<ng-template #cardLoader>
  <t4-loader></t4-loader>
</ng-template>
