<button *ngIf="type === 'button'"
        class="btn dropdown-toggle btn-primary border btn-small"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false">
  {{label | translate}}
</button>
<a *ngIf="type === 'icon'"
   class="btn icon-burger-mini" href="#" id="dropdownMenuLink" data-toggle="dropdown">
</a>

<div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton" x-placement="bottom-start" style="position: absolute; transform: translate3d(0px, 41px, 0px); top: 0px; left: 0px; will-change: transform;">
  <ng-content></ng-content>
</div>
