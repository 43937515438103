import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'fan-nav-header',
  templateUrl: 'nav-header.component.html',
  styleUrls: ['./nav-header.component.scss']
})

export class NavHeaderComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {
  }
}
