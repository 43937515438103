
<!-- MODAL HEADER-->
<div class="row modal-bar">

    <div class="col-sm-6">
        <h5 class="modal-title">{{ 'FAN.ORDERDETAILS.MODAL.HEADER' | translate:{id:orderDetails.Id} }}</h5>
    </div>

    <div class="col-sm-6">
        <div class="modal-actions">
            <div class="modal-close">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span (click)="dialogRef.close(false);">×</span>
                </button>
            </div>
        </div>
    </div>
  </div>


  <div class="object-actions mt-3" *ngIf="orderDetails.AllowEticketPrinting">
       <button class="btn dropdown-toggle btn-primary border btn-small" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i class="fas fa-ticket-alt"></i>
        {{'ORDER.DETAILS.BUTTON.TICKETS' | translate}}
      </button>

      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" x-placement="bottom-start" style="position: absolute; transform: translate3d(0px, 41px, 0px); top: 0px; left: 0px; will-change: transform;">
        <a class="dropdown-item" (click)="displayDownloadDialog();" [class.disabled]="orderDetails.AllowEticketPrinting === false"  href="#" >
          <i class="fas fa-download mr-2"></i>
          {{'ORDER.DETAILS.BUTTON.TICKETS.DOWNLOAD-ETICKET' | translate}}
        </a>
      </div>
  </div>
  
  <t4-order-header [isIbaActive]="isIbaActive" [details]="orderDetails" [customerUrl]="'member/details/'" ></t4-order-header>

  <t4-tabs (selected)="onTabChange($event)" >

    <t4-tab tabTitle="FAN.ORDERDETAILS.TAB.ORDERLINES" id="orderlines">
      <t4-order-lines [isIbaActive]="isIbaActive" [orderLines]="orderDetails.OrderLines"></t4-order-lines>
    </t4-tab>

    <t4-tab tabTitle="FAN.ORDERDETAILS.TAB.DISCOUNTS" id="discounts">
      <t4-discount-lines [discountLines]="orderDetails.DiscountTotals"></t4-discount-lines>
    </t4-tab>

    <t4-tab tabTitle="FAN.ORDERDETAILS.TAB.SUBSCRIPTIONS" id="subscriptions">
      <t4-subscription-lines [orderDetails]="orderDetails"></t4-subscription-lines>
    </t4-tab>

    <t4-tab tabTitle="FAN.ORDERDETAILS.TAB.PAYMENTHISTORY" id="payment-history">
      <fan-payment-overview #paymentOverview></fan-payment-overview>
    </t4-tab>

  </t4-tabs>


