import { Component, OnInit, Inject, Input } from '@angular/core';

@Component({
  selector: 't4-page-warning',
  template: `
        <div class="mt-3 alert alert-warning" role="alert">
          <i class="fa fa-exclamation-circle"></i>
          {{ infoText | translate : infoParams }}
        </div>        
  `
})
export class PageWarningComponent implements OnInit {
  
  constructor() { }

  public infoText = '';
  public infoParams = {};
 
  @Input()
  set warning(txt: string) {
    this.infoText = txt;
  }
  
  @Input()
  set parameters(params: any)
  {
    if (params != null)
      this.infoParams = params;
  }

  ngOnInit() { 
  }
}