
<div class="row">
  <div *ngIf="type !== DateEditorType.Time" class="col-lg-{{ colWidth }}">
    <div class="form-group">
      <label class="form-control-label" *ngIf="labelDate">{{ labelDate | translate}}<span class="tx-danger" *ngIf="required">*</span></label>
      <input [disabled]="disabled" class="form-control" [(ngModel)]="dateText" [beforeToday]="beforeToday" datepicker placeholder="{{datePlaceholder | translate}}" (change)="onDateChange($event)"> <!-- startDateChanged -->
      <!-- <t4-display-validation controlName="StartDate" [form]="form" [messages]="validationMessages"></t4-display-validation> -->
    </div>
  </div>

  <div *ngIf="type !== DateEditorType.Date" class="col-lg-{{ colWidth }}">
    <div class="form-group">
      <label class="form-control-label" *ngIf="labelTime">{{ labelTime | translate}}<span class="tx-danger" *ngIf="required">*</span></label>
      <input [disabled]="disabled" class="form-control" [(ngModel)]="timeText" type="time" placeholder="{{timePlaceholder | translate}}" (change)="onDateChange($event)"/>
    </div>
  </div>
</div>
 