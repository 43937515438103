import { AfterViewInit, Component, Input, OnInit, ViewChild } from "@angular/core";
import { IdentificationCustomer } from '../../../controls/customer-identification-control/customer-identification-control.component';
import { ListViewSelectionMode } from "../../../enums/list-view-selection-mode.enum";
import { ProductTypeEnum } from "../../../enums/product-type.enum";
import { PagedResult } from "../../../models/list-view/pagedResult";
import { ListViewComponent } from "../../list-view/list-view.component";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";

@Component({
    selector: 't4-secondary-order-lines',
    templateUrl: './secondary-order-lines.component.html'
})
export class SecondaryOrderLinesComponent implements  OnInit, AfterViewInit {
  public ProductTypeEnum = ProductTypeEnum;
  @Input() public orderLines: Array<any>;
  @Input() public isIbaActive: boolean = false;

  @ViewChild(ListViewComponent) listVw : ListViewComponent;

  public listOptions = {
    search: false,
    showBarcodeSearch: false,
    pageSize: 7 ,
    noItemsText: 'T4.ORDERDETAILS.ORDERLINES.NOLINES',
    selectionMode: ListViewSelectionMode.None,
    disableSort: true,
    renderInCard: false,
    noSpacing: true,
    headers: [
        {sortField: null, label: 'T4.SECONDARY.ORDERDETAILS.HEADER.PRODUCT', hideOn: null},
        {sortField: null, label: 'T4.SECONDARY.ORDERDETAILS.HEADER.SECTIONROWSEAT', hideOn: null, width: 150},
        {sortField: null, label: 'T4.SECONDARY.ORDERDETAILS.HEADER.INTHENAMEOF', hideOn: null, width: 150},
        {sortField: null, label: 'T4.SECONDARY.ORDERDETAILS.HEADER.ORIGINALFROM', hideOn: null, width: 200},
        {sortField: null, label: 'T4.SECONDARY.ORDERDETAILS.HEADER.ORIGINALORDER', hideOn: null, width: 200},
        {sortField: null, label: 'T4.SECONDARY.ORDERDETAILS.HEADER.ORIGINALSALEDATE', hideOn: null, width: 200},
        
        {sortField: null, label: 'T4.ORDERDETAILS.HEADER.TOTAL', hideOn: null, width: 80, class: 'text-right' },
    ]
  };

public orderLinesLength;
public pagedOrderLines;
private orderUrl: string = 'sales/orders/details/';

  constructor(
    private router: Router,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<SecondaryOrderLinesComponent>) { }

  ngOnInit(): void {
      
      if(this.orderLines!=null && this.orderLines.length>0){
        this.orderLines = this.orderLines.filter(ol => ol.ProductType !== 5);

        this.orderLinesLength = this.orderLines.length;
      } else {
        this.orderLinesLength = 0;
      }
      

      
  }

  ngAfterViewInit(): void {
      //throw new Error("Method not implemented.");
  }

  doPage($event) {

    if(this.orderLinesLength>0){
      this.pagedOrderLines = this.orderLines.slice(($event.pageNr - 1) * $event.pageSize, ($event.pageNr) * $event.pageSize);
      
      let d : PagedResult = {
        TotalRecords: this.orderLinesLength,
        Records: this.pagedOrderLines
      };
      
      this.listVw.Data = d;
    }
 
  }

  constructCustomerIdentificationModel(orderLine): IdentificationCustomer {
    return {
      CalculatedName: orderLine.TicketCustomerCalculatedName,
      CustomerType: orderLine.TicketCustomerType,
      CustomerName: orderLine.TicketCustomer,
      IsIdentified: orderLine.TicketCustomerIsIdentified,
      CustomerNumber: orderLine.TicketCustomerNumber,
      TicketCustomerId: orderLine.TicketCustomerId
    }
  }

  constructOriginalCustomerIdentificationModel(orderLine): IdentificationCustomer {
    return {
      CalculatedName: orderLine.OriginalCustomerName,
      CustomerType: orderLine.TicketCustomerType,
      CustomerName: orderLine.TicketCustomer,
      IsIdentified: orderLine.OriginalCustomerIsIdentified,
      TicketCustomerId: orderLine.OriginalCustomerId
    };
  }

  routeToOrder(oId){
    this.dialogRef.close(oId);
  }
}