import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 't4-input-label',
  templateUrl: 'input-label.component.html'
})

export class InputLabelComponent {
  @Input() label: string;
  @Input() isRequired: boolean;

  constructor() {
  }
}
