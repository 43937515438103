import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { BASE_FM_CONFIG } from './app/injectors';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

async function main() {

  try {
    const url = environment.configApiURL + 'config/' + window.location.hostname;

    const data = await fetch(url);
    const config = await data.json();

    sessionStorage.setItem('endpoint', config.Config.apiUrl);
    sessionStorage.setItem('ticketShopURL', config.Config.ticketShopURL);
    sessionStorage.setItem('phoneValidation', config.Config.PhoneValidation);

    //store the state if we don't already have one.
    if (sessionStorage.getItem('state') === null && config.State !== null) {
      //no state, store a new one. We only do this once, otherwise the shopper will get a new session upon every page refresh
      sessionStorage.setItem('state', config.State);
    }

    await platformBrowserDynamic([{ provide: BASE_FM_CONFIG, useValue: config.Config }]).bootstrapModule(AppModule);
  }
  catch (error)
  {
    console.error(error);
    window.location.href = "unavailable.html" // TODO LVG, does this exist? used to redirect to ticketshop when in trouble, is this still possible at this point?
  }
}

main();