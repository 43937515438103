<div class="row my-5" *ngIf="orderDetails !== null">

    <div class="col-md-6">
      <div class="important-info info-highlight">
        <div class="row">
  
        <div class="col-md-6">
            <div class="row">
  
              <div class="col-10">
                <p class="info-label">{{'T4.GENERIC.INTHENAMEOF' | translate}}</p>
                <p class="info-data">
                  <a href="#"
                  (click)="linkToCustomer(orderDetails.CustomerId)"><t4-customer-identification-control [isIbaActive]="isIbaActive" [showCustomerNumber]="true" [customer]="orderDetails.OrderIdentificationCustomer"></t4-customer-identification-control></a>
                </p>
              </div>
              <div class="col-2">
                <i class="fas fa-user"></i>
              </div>
            </div>
            <div class="row">
  
              <div class="col-10">
                <p class="info-label">{{'T4.ORDERHEADER.ORDERDATE' | translate}}</p>
                <p class="info-data">{{orderDetails.OrderDate | localDateTime | slice:0:10 }}
                  {{orderDetails.OrderDate | localTime }}</p>
              </div>
              <div class="col-2">
                <i class="far fa-calendar-alt"></i>
              </div>
  
            </div>
  
            <div class="row">
              <div class="col-10">
                <p class="info-label">{{'T4.ORDERHEADER.SALESCHANNEL' | translate}}</p>
                <p class="info-data">{{orderDetails.SalesChannelName}}
              </div>
              <div class="col-2">
                <i class="fas fa-cash-register"></i>
              </div>
            </div>
  
            <div class="row">
              <div class="col-10">
                <ng-container *ngIf="!orderDetails.Deleted">
                  <p class="info-label">{{'T4.ORDERHEADER.PAYMENTSTATUS' | translate}}</p>
                  <p class="info-data">
                    <span class="text-right">
                      <span *ngIf="orderDetails.Payed" class="chipbox chipbox-closed">{{'T4.ORDERHEADER.PAYMENTSTATUS.PAID' | translate}}</span>
                      <span *ngIf="!orderDetails.Payed" class="chipbox chipbox-open">{{'T4.ORDERHEADER.PAYMENTSTATUS.OUTSTANDING' | translate}}</span>
                    </span>
                  </p>
  
                </ng-container>
  
                <ng-container *ngIf="orderDetails.Deleted">
                  <p class="info-label">{{'T4.ORDERHEADER.CANCELLED' | translate}}</p>
                  <p class="info-data">{{orderDetails.Deleted | localDateTime }}
                  </p>
  
                </ng-container>
  
              </div>
              <div class="col-2">
                <i class="fas fa-stream"></i>
              </div>
            </div>
        </div>
  
        <div class="col-md-6">
            <div class="row">
              <div class="col-10">
                <p class="info-label">{{'T4.SECONDARY.ORDER' | translate}}</p>
      
                <hr>
                <div class="row">
                  <div class="col-md-7">
                    <p class="info-data">{{'T4.ORDERHEADER.TOTALAMOUNT' | translate}}</p>
                  </div>
                  <div class="col-md-5 text-right">
                    <p class="info-data">{{orderDetails.TotalInclVat  | currency:'EUR'}}</p>
                  </div>
                </div>
              </div>
              <div class="col-2">
                <i class="fas fa-file-invoice"></i>
              </div>
  
            </div>
        </div>
  
        </div>
      </div>
    </div>
  
  
  
  
    <div class="col-md-6">
      <div class="important-info">
  
  
          <div class="row">
            <div class="col-5">
              <p class="info-label">{{'T4.ORDERHEADER.DELIVERYMETHOD' | translate}}</p>
              <p class="info-data"> {{orderDetails.DeliveryMethodName | replaceOnEmpty }}</p>
            </div>
            <div class="col-1">
              <i class="fas fa-truck-loading"></i>
            </div>
  
            <div class="col-5">
              <p class="info-label">{{'T4.ORDERHEADER.PRIMARYPAYMENTMETHOD' | translate}}</p>
              <p class="info-data">{{getPrimaryPaymentMethod() }}</p>
            </div>
            <div class="col-1">
              <i class="fas fa-wallet"></i>
            </div>
          </div>
  
          <div class="row">
            <div class="col-5">
              <p class="info-label">{{'T4.ORDERHEADER.REFERENCE' | translate}}</p>
              <p class="info-data info-data--wrap">{{orderDetails.Reference  | replaceOnEmpty }}</p>
            </div>
            <div class="col-1">
              <i class="fas fa-file-alt"></i>
            </div>
  
            <div class="col-5">
              <p class="info-label">{{'T4.ORDERHEADER.PAYMENTSTATUS.OUTSTANDING' | translate}}</p>
              <p class="info-data">{{orderDetails.Balance | currency:'EUR'}}</p>
            </div>
            <div class="col-1">
              <i class="fas fa-hand-holding"></i>
            </div>
          </div>
  
          <div class="row">
            <div class="col-5">
              <p class="info-label">{{'T4.ORDERHEADER.NOOFTICKETS' | translate}}
              </p>
              <p class="info-data" data-toggle="tooltip" data-placement="bottom" title="{{orderDetails.Summary}}">{{totalTickets}}</p>
            </div>
            <div class="col-1">
              <i class="fas fa-cart-plus"></i>
            </div>
            <div class="col-5">
              <p class="info-label">{{'T4.ORDERHEADER.PENDING' | translate}}</p>
              <p class="info-data">{{orderDetails.PendingPaymentBalance | currency:'EUR'}}</p>
            </div>
            <div class="col-1">
              <i class="far fa-credit-card"></i>
            </div>
          </div>
  
        </div>
    </div>
  </div>
  