
    <div class="ticket_template_info_text ticket_template_info_text_status green" *ngIf="ticket.TicketStatus.Status === MyTicketStatusEnum.Bought">
        {{ 'TICKET.IBA.STATUS.BOUGHT' | translate }}
    </div>
    <div class="ticket_template_info_text ticket_template_info_text_status blue" *ngIf="ticket.TicketStatus.Status === MyTicketStatusEnum.MyClaimedTicket">
        {{ 'TICKET.IBA.STATUS.READY-FOR-DOWNLOAD' | translate }}
    </div>
    <div class="ticket_template_info_text ticket_template_info_text_status red" *ngIf="ticket.TicketStatus.Status === MyTicketStatusEnum.IdentificationRequired">
        {{ 'TICKET.IBA.STATUS.IDENTIFICATION-REQUIRED' | translate }}
    </div>
    <div class="ticket_template_info_text ticket_template_info_text_status red" *ngIf="ticket.TicketStatus.Status === MyTicketStatusEnum.AssignmentRequired">
        {{ 'TICKET.IBA.STATUS.ACTION-REQUIRED' | translate }}
    </div>
    <div class="ticket_template_info_text ticket_template_info_text_status orange" *ngIf="ticket.TicketStatus.Status === MyTicketStatusEnum.InvitationPending">
        {{ 'TICKET.IBA.STATUS.EXPECTING-RESPONSE' | translate }}
    </div>
    <div class="ticket_template_info_text ticket_template_info_text_status green" *ngIf="ticket.TicketStatus.Status === MyTicketStatusEnum.InvitationRevoked">
        {{ 'TICKET.IBA.STATUS.INVITATION-REVOKED' | translate }} 
    </div>
    <div class="ticket_template_info_text ticket_template_info_text_status green" *ngIf="ticket.TicketStatus.Status === MyTicketStatusEnum.InvitationDeclined">
        {{ 'TICKET.IBA.STATUS.INVITATION-DECLINED' | translate }} 
    </div>
    <div class="ticket_template_info_text ticket_template_info_text_status green" *ngIf="ticket.TicketStatus.Status === MyTicketStatusEnum.Assigned">
        {{ 'TICKET.IBA.STATUS.ACCEPTED' | translate }} 
    </div>
    <div class="ticket_template_info_text ticket_template_info_text_status orange" *ngIf="ticket.TicketStatus.Status === MyTicketStatusEnum.SecondaryMarketOnResale">
        {{ 'TICKET.IBA.STATUS.POSTED-FOR-EXCHANGE' | translate }}
    </div>
    <div class="ticket_template_info_text ticket_template_info_text_status green" *ngIf="ticket.TicketStatus.Status === MyTicketStatusEnum.SecondaryMarketSold">
        {{ 'TICKET.IBA.STATUS.SOLD' | translate }}
    </div>
