
 <div class="table-responsive">

    <table class="rounded table text-dark table-striped clickable">

      <thead>
        <th>{{'FAN.PAYMENT-OVERVIEW.HEADER.DATE' | translate}}</th>
        <th></th>
        <th>{{'FAN.PAYMENT-OVERVIEW.HEADER.TYPE' | translate}}</th>
        <th>{{'FAN.PAYMENT-OVERVIEW.HEADER.REFERENCE' | translate}}</th>
        <th>{{'FAN.PAYMENT-OVERVIEW.HEADER.PAYMENTMETHOD' | translate}}</th>
        <th>{{'FAN.PAYMENT-OVERVIEW.HEADER.PAYMENTTYPE' | translate}}</th>
        <th>{{'FAN.PAYMENT-OVERVIEW.HEADER.HANDLED' | translate}}</th>
        <th>{{'FAN.PAYMENT-OVERVIEW.HEADER.STATUS' | translate}}</th>
        <th>{{'FAN.PAYMENT-OVERVIEW.HEADER.DETAILS' | translate}}</th>
        <th class="text-right">{{'FAN.PAYMENT-OVERVIEW.HEADER.AMOUNT' | translate}}</th>
      </thead>

      <tbody>

        <ng-container *ngIf="pagedHistoryLines !== null && pagedHistoryLines.length == 0; else subscriptionlines">
            <tr>
              <td colspan="10">{{'FAN.PAYMENT-OVERVIEW.NOPAYMENTSFORORDER' | translate}}</td>
            </tr>
        </ng-container>

        <ng-template #subscriptionlines>
          <tr *ngFor="let pl of pagedHistoryLines">

            <td style="width: 100px">{{pl.ReceivedTimeStamp | localDate | replaceOnEmpty }}</td>
            <td>{{pl.ReceivedTimeStamp | localTime | replaceOnEmpty }}</td>
            <td>{{pl.Type }}</td>
            <td>{{pl.PaymentReference | replaceOnEmpty }}</td>
            <td>{{pl.PaymentMethodName }}</td>
            <td [ngSwitch]="pl.PaymentTypeId">
                <span *ngSwitchCase="1">{{'FAN.PAYMENT-OVERVIEW.HEADER.PAYMENTTYPE.DEBETCARD' | translate}}</span>
                <span *ngSwitchCase="2">{{'FAN.PAYMENT-OVERVIEW.HEADER.PAYMENTTYPE.CASH' | translate}}</span>
                <span *ngSwitchCase="3">{{'FAN.PAYMENT-OVERVIEW.HEADER.PAYMENTTYPE.OTHER' | translate}}</span>
                <span *ngSwitchDefault>-</span>
            </td>

            <td>{{pl.Handled | booleantype }}</td>
            <td>{{ getPaymentStatusText(pl.PaymentStatus) | replaceOnEmpty }}</td>
            <td style="width: 600px;">{{pl.Description | replaceOnEmpty }}</td>
            <td class="text-right">{{ pl.Amount | currency:'EUR' | replaceOnEmpty }}</td>
          </tr>
        </ng-template>

      </tbody>
    </table>

</div>

<!-- <div class="list-actions">
    <input type="checkbox" id="showDetails"  name="showDetails" (change)="onShowDetailsChange($event)" [checked]="false" /> <label for="showDetails">&nbsp;Toon details </label>
</div> -->


<t4-pager (onPage)="doHistoryPage($event)" #historyPagerComponent></t4-pager>







