
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {ApiDetails} from '../../models/http/api-details';
import {BadDataError} from '../../models/http/errors/bad-data-error';
import {HttpError} from '../../models/http/errors/http-error';
import {NotFoundError} from '../../models/http/errors/not-found-error';
import { UnauthorizedError } from '../../models/http/errors/unauthorized-error';
import {SearchDataRequest} from '../../models/http/search-data-request';
import {EnvironmentService} from '../environment.service';

@Injectable()
export class DataService {
  private apiDetails: ApiDetails;
  constructor(public http: HttpClient, private environment: EnvironmentService) {
    this.apiDetails = this.environment.loadConfigurations();
  }

  public entityName: string;

  public getHeaders(): HttpHeaders {
    const header = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return header;
 }

  getUrl() {
    console.error('Attempt to get the V1 API Url. This no longer works.');
    return 'https://this-url-is-no-longer-supported.tymes4.com/' + this.entityName;

    //return this.apiDetails.apiURL + '/api/' + this.entityName;
  }

  getAll(page: number = 1, per_page: number = 99999999, sortField: string = 'Id', asc: boolean = true, filter: string = null) {

    // filter cannot be escaped automatically as it might contain an '=' sign
    // if (filter != null) filter = encodeURIComponent(filter);

    const ascBit = asc ? '1' : '0';
    let url = this.getUrl() + `?page=${page}&per_page=${per_page}&sort=${sortField}&asc=${ascBit}`;

    if (filter != null) {
      url += `&${filter}`;
    }

    return this.http.get(url).pipe(catchError(this.handleError));
  }

  searchBySearchDataRequest(dataRequest: SearchDataRequest): Observable<HttpResponse<unknown>> {
    let additionalFilters = '';

    for (const key of Object.keys(dataRequest.staticFilters)) {
      additionalFilters += `&${key}=${dataRequest.staticFilters[key]}`;
    }

    for (const key of Object.keys(dataRequest.importantFilters)) {
      additionalFilters += `&${key}=${dataRequest.importantFilters[key]}`;
    }

    for (const key of Object.keys(dataRequest.searchFilters)) {
      additionalFilters += `&${key}=${dataRequest.searchFilters[key]}`;
    }

    return this.search(
      dataRequest.searchText,
      dataRequest.pageNr,
      dataRequest.pageSize,
      dataRequest.sortField,
      dataRequest.sortAsc,
      additionalFilters
    );
  }

  search(searchText, pageNr: number = 1, pageSize: number = 99999999, sort: string = 'Id', asc: boolean = true, additionalFilters: string = ''): Observable<HttpResponse<unknown>> {
    searchText = encodeURIComponent('%' + searchText + '%');
    const ascIndicator = asc ? '1' : '0';
    let url = this.getUrl() + `/search?sort=${sort}&page=${pageNr}&per_page=${pageSize}&asc=${ascIndicator}&searchText=${searchText}`;

    if (additionalFilters !== '') {
      if (additionalFilters[0] !== '&') {
        url += '&';
      }

      url += additionalFilters;
      url += '&filter=1';
    }

    return this.http.get(url, {observe: 'response'}).pipe(catchError(this.handleError));
  }

  get(id) {
    return this.http.get(this.getUrl() + '/' + id).pipe(catchError(this.handleError));
  }

  update(resource) {
    return this.http.put(this.getUrl(), JSON.stringify(resource), { headers: this.getHeaders() }).pipe(catchError(this.handleError));
  }

  create(resource) {
    return this.http.post(this.getUrl(), JSON.stringify(resource), { headers: this.getHeaders() }).pipe(catchError(this.handleError));
  }

  delete(id) {
    return this.http.delete(this.getUrl() + '/' + id).pipe(catchError(this.handleError));
  }

  protected handleError(error: Response) {
    if (error.status === 400) {
      return observableThrowError(new BadDataError(error.json));
    }
    if (error.status === 401) {
      return observableThrowError(new UnauthorizedError())
    }
    if (error.status === 404) {
      return observableThrowError(new NotFoundError());
    }
    return observableThrowError(new HttpError(error));
  }

  protected allowAnonymousHeaders(): HttpHeaders {
    // If signed in keep using the authentication
    if (sessionStorage.getItem('userToken')) {
      return new HttpHeaders({});
    }

    return new HttpHeaders({ 'no-auth': 'true' });
  }
}
