export enum MyTicketStatusEnum
{
    Bought = 'Bought',
    MyClaimedTicket = 'MyClaimedTicket',
    IdentificationRequired = 'IdentificationRequired',
    AssignmentRequired = 'AssignmentRequired',
    InvitationPending = 'InvitationPending',
    InvitationRevoked = 'InvitationRevoked',
    InvitationDeclined = 'InvitationDeclined',
    Assigned = 'Assigned',
    SecondaryMarketOnResale = 'SecondaryMarketOnResale',
    SecondaryMarketSold = 'SecondaryMarketSold'

}