import { Observable } from 'rxjs';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import {UploadMessageComponent} from '../components/upload-message/upload-message.component';
import {UploadMessageData} from '../models/common/upload-message-data';


@Injectable({providedIn: 'root'})
export class UploadMessageService {

  constructor(private dialog: MatDialog) { }

  public confirm(data: UploadMessageData = {}, width: any = '380px'): any { //tymes4v2, hack to make it work again
    data.title = data.title || 'Confirm';
    data.message = data.message || 'Are you sure?';
    let dialogRef: MatDialogRef<UploadMessageComponent>;

    dialogRef = this.dialog.open(UploadMessageComponent, {
      width: width,
      disableClose: true,
      panelClass: 'confirmBox',
      data: data
    });
    return dialogRef.afterClosed();
  }
}
