import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 't4-dash-small-chart',
  templateUrl: 'dash-smallchart.component.html'
})

export class DashSmallChartComponent implements OnInit {
  
  constructor() {
  }

  @Input() series = null;
  @Input() color = '#000';

  chart = null;
  plotOptions = null;
  dataLabels = null;
  stroke = null;
  fill = null;
  colors = null;
  toolTip = null;

  ngOnInit() {

    this.chart = {
      height: 40,
      width: '100px',
      type: 'bar',
      sparkline: {
        enabled: true
      },
    };

    this.plotOptions = {
      bar: {
        horizontal: false,
        endingShape: 'flat',
        columnWidth: '65%',
      }
    };

    this.dataLabels = {
      enabled: false
    }
  
    this.stroke =  {
      show: true,
      width: 2,
      colors: ['transparent']
    }


    this.fill = {
        opacity: 1
    }

    this.colors = this.color;

    this.toolTip = {
        followCursor: false,
        theme: 'dark',
        x: {
          show: false
        },
        marker: {
          show: false
        },
        y: {
          title: {
            formatter: function () {
              return ''
            }
          }
        }
      };
  
  }

 
}
