import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';
import {ApiDetails} from '../models/http/api-details';


@Injectable({providedIn: 'root'})
export class EnvironmentService {

  private readonly CONFIG_URL = 'assets/environments/environment.json';
  private configuration: ApiDetails;

  constructor(private http: HttpClient) {
  }

  public init(): Observable<ApiDetails> {
    const reqHeader = new HttpHeaders({
      'no-auth': 'true'
    });

    const tempDetails = {
      apiURL: "test",
      production: false,
    
      messageDuration: 300,
      enableErrorPage: true,
      companiesFromExternalSource: false,
      entranceMode: null,// : 'single'|'multi' = 'single',
      clientUrl: 'test',
      versionCheckURL: 'test',
      versionCheckInterval: 999999999,
      ticketShopURL: 'test'
    }

    return of(tempDetails);

    return this.http.get<ApiDetails>(this.CONFIG_URL, {headers: reqHeader}).pipe(
      tap(config => {
        this.configuration = config;
      })
    );
  }

  public loadConfigurations(): ApiDetails {
    return this.configuration;
  }
}
