import {HttpClient} from '@angular/common/http';

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DataService, EnvironmentService, Language} from '@tymes4-shared';


@Injectable()
export class LanguageServiceOld extends DataService {
  constructor(http: HttpClient, environmentService: EnvironmentService) {
    super(http, environmentService);
    this.entityName = 'language';
  }

  getActive(): Observable<Language> {
    const url = `${this.getUrl()}/active/`;
    return this.http.get(url, {headers: this.allowAnonymousHeaders()}) as Observable<Language>;
  }

  getTranslations(languageCode: string) {
    const url = `${this.getUrl()}/${languageCode}/translations`;
    return this.http.get(url, {headers: this.allowAnonymousHeaders()});
  }

  getLanguages(): Observable<Array<Language>> {
    const url = `${this.getUrl()}`;
    return this.http.get(url) as Observable<Array<Language>>;
  }
}
