import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 't4-list-value-boolean',
  templateUrl: './list-value-boolean.component.html',
  styleUrls: ['./list-value-boolean.component.scss']
})
export class ListValueBooleanComponent implements OnInit {

  passedValue: boolean = null;
  toggleAllowed: boolean = false;

  @Input()
  set value(b: boolean) {
    this.passedValue = b;
  }

  @Input()
  set allowToggle(b: boolean) {
    this.toggleAllowed = b;
  }

  @Output()
  onToggle: EventEmitter<boolean> = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  onSwitch($event)
  {
    if (this.toggleAllowed)
    {
      this.onToggle.emit($event.target.checked);
    }

  }
}
