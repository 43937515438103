import { ListViewComponent } from './../list-view/list-view.component';
import { ListViewSelectionMode } from './../../enums/list-view-selection-mode.enum';
import { PagedResult } from './../..//models/list-view/pagedResult';
import { Component, Input, OnInit, ViewChild, AfterViewInit } from "@angular/core";

@Component({
    selector: 't4-stock-mutations',
    templateUrl: './stock-mutations.component.html'
    
})
export class StockMutationsComponent implements OnInit, AfterViewInit {
    
    @Input() public stockMutations: Array<any>;

    @ViewChild(ListViewComponent) listVw : ListViewComponent;

    constructor() {}

    public listOptions = {
        search: false,
        pageSize: 12,
        noItemsText: 'T4.STOCKMUTATIONS.NOITEMSFOUND',
        SelectionMode: ListViewSelectionMode.None,
        disableSort: true,
        headers: [
            {sortField: null, label: 'T4.STOCKMUTATIONS.HEADER.DATE'},
            {sortField: null ,label: 'T4.STOCKMUTATIONS.HEADER.MUTATION'},
            {sortField: null, label: 'T4.STOCKMUTATIONS.HEADER.MUTATEDBY'},
          ]
    };

    public stockMutationsLength;
    public pagedStockMutations;
    
    doPage($event) {
        this.pagedStockMutations = this.stockMutations.slice(($event.pageNr - 1) * $event.pageSize, ($event.pageNr) * $event.pageSize);

        let d : PagedResult = {
            TotalRecords: this.stockMutationsLength,
            Records: this.pagedStockMutations
        };

        this.listVw.Data = d;
    }

    ngOnInit() {
        console.log('t4-stock-mutations this.stockMutations', this.stockMutations);
        this.stockMutationsLength = this.stockMutations.length;
    }

    ngAfterViewInit() {
    
    }
}