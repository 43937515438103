import { PagerComponent } from './../../pager/pager.component';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Inject, ViewChild, AfterViewInit, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpLoaderService } from '../../../services/http-loader.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {LoggingService} from '../../../services/logging.service';
import {formatDate} from 'ngx-bootstrap/chronos';

@Component({
  selector: 't4-print-or-download-tickets',
  templateUrl: './print-or-download-tickets.component.html'
})
export class PrintOrDownloadTicketsComponent implements OnInit, AfterViewInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    public dialogRef: MatDialogRef<PrintOrDownloadTicketsComponent>,
    private loader: HttpLoaderService,
    private logging : LoggingService,
    private translate : TranslateService
  ) { }

  public form: FormGroup;
  public saving = false;
  // public orderDetails:any = {};
  public printOrderDetails:any = {};
  public dialogMode:string;
  //public orderHasHardcards: boolean = false;
  public eticketSalesChannels: any[] = [];
  public selectableSalesChannels: any[] = [];
  public selectedSalesChannelMissesTemplates = false;
  public printableTickets: any[] = [];
  public pagedPrintableTickets: any[] = [];
  public lastUsedPage = null;
  public selectedObjects = [];

  public ticketsSelected : number = 0;
  public ticketsTotal: number = 0;

  public reprintChecked = false;

  public allowSalesChannelSelect = false;

  public secondaryTicketingActive = false;
  public isIbaActive: boolean = false;

  @ViewChild(PagerComponent) pagerComponent: PagerComponent;


  public selectableCutModes = [
    {Name: this.translate.instant('T4.PRINTORDOWNLOADTICKETS.CUTMODE.AFTERTICKET'), Id: 1},
    {Name: this.translate.instant('T4.PRINTORDOWNLOADTICKETS.CUTMODE.AFTERORDER'), Id: 2},
    {Name: this.translate.instant('T4.PRINTORDOWNLOADTICKETS.CUTMODE.NEVER'), Id: 3}
  ];

  public selectablePackagingSlipOptions = [
    {Name: this.translate.instant('T4.PRINTORDOWNLOADTICKETS.PACKAGINGSLIP.NONE'), Id: 1},
    {Name: this.translate.instant('T4.PRINTORDOWNLOADTICKETS.PACKAGINGSLIP.MAINCUSTOMER'), Id: 2},
    {Name: this.translate.instant('T4.PRINTORDOWNLOADTICKETS.PACKAGINGSLIP.TICKETCUSTOMER'), Id: 3},
  ];

  public duplicationReasons = [];

  get isAnySelectable() {
    return this.printableTickets.some(ticket => ticket.Selectable);
  }

  ngOnInit() {

    this.form = new FormGroup({
      eventId: new FormControl(null),
      packagingSlip: new FormControl(2),
      cutMode: new FormControl(1),
      SalesChannelId: new FormControl(null),
      IsDuplicate: new FormControl(false),
      DuplicationReasonId: new FormControl(1),
    });

    this.printOrderDetails = this.passedData.payload;
    this.dialogMode = this.passedData.mode;
    this.allowSalesChannelSelect = this.passedData.allowSalesChannelSelect;
    this.isIbaActive = this.passedData.isIbaActive;

    //this.orderHasHardcards = printOrderDetails.HasHardcards;
    this.printableTickets = this.printOrderDetails.PrintableTickets;
    this.eticketSalesChannels = this.printOrderDetails.ETicketPrintableChannels;
    this.duplicationReasons = this.printOrderDetails.DuplicationReasons;

    this.ticketsTotal = (!this.printableTickets || this.printableTickets == null) ? 0 : this.printableTickets.length;

    for (let ticket of this.printableTickets) {
      if (ticket.TicketSwapStatus != null && ticket.TicketSwapStatus != "None") {
        this.secondaryTicketingActive = true;
        break;
      }
    }

    setTimeout(() => {
      this.doPreselect();

      this.updateReasonDD();
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.setSearchAndPage(1);
      this.allSelectionToggle(true, true);
    });

  }

  doPage(pageArgs) {
    this.setSearchAndPage(pageArgs.pageNr);
  }

  setSearchAndPage(pageNr: number) {
    this.lastUsedPage = pageNr;
    let length = this.printableTickets.length; //we need a copy
    this.pagerComponent.setObjectCount(length);
    this.pagedPrintableTickets =this.printableTickets.slice((pageNr - 1) * this.pagerComponent.pageSize, (pageNr) * this.pagerComponent.pageSize);
    this.pagerComponent.setPage (pageNr);
  }




  handleSalesChannelChange(e) {
    const channelId = e.Id;

    let selectedChannel = this.eticketSalesChannels.filter(function (sc)   {
      if (sc.Id === channelId) return sc;
    });

    this.selectedSalesChannelMissesTemplates = (selectedChannel[0].AllTemplatesAvailable === false);
  }


  toggleSelection(ticket) {


    if (ticket == null) return;

    if (this.isItemSelected(ticket)) {
      //remove from the selection
      var index = this.selectedObjects.indexOf(ticket.Id);
      this.selectedObjects.splice(index, 1);
    }
    else {
      this.selectedObjects.push(ticket.Id);
    }

    this.ticketsSelected = this.selectedObjects.length;
  }

  isItemSelected(ticket) {

    if (!ticket) return null;

    if (this.selectedObjects == null && this.selectedObjects.length == 0)
      return false;

    var found = this.selectedObjects.filter(function(Id){
                      if (Id == ticket.Id) return Id;
                    });

    return (found != null && found.length > 0);
  }

  allSelectionToggle(toggleOn, filter = false) {
    this.selectedObjects = [];
    this.ticketsSelected = 0;

    if (!toggleOn) {
      return;
    }

    const ticketsToSelect = filter
      ? this.printableTickets.filter(x => x.TicketSwapStatus == null || x.TicketSwapStatus == "None")
      : this.printableTickets;

    for (const ol of ticketsToSelect) {
      this.selectedObjects.push(ol.Id);
    }

    this.ticketsSelected = this.selectedObjects.length;
  }


  doPreselect() {

    if (this.dialogMode !=='print' && this.allowSalesChannelSelect) {
      this.selectableSalesChannels = this.eticketSalesChannels.filter(function (sc)   {
        if (sc.AllTemplatesAvailable === true || sc.IsDefault === true) return sc;
      });

      let defaultChannel = this.eticketSalesChannels.filter(function (sc)   {
        if (sc.IsDefault === true) return sc;
      });

      this.selectedSalesChannelMissesTemplates = (defaultChannel[0].AllTemplatesAvailable === false);
      this.form.patchValue ({ SalesChannelId: defaultChannel[0].Id});

    }

  }

  doDownload(downloadInBackground: boolean) : void {

    const o = this.form.value;

    o.SelectedEventplacementIds = this.selectedObjects;
    o.DownloadInBackground = downloadInBackground;

    this.saving = true;
    this.dialogRef.close(o);

  }

  public ConstructLastPrintToolTip (item) {
    return this.ConstructToolTip(item, 'T4.PRINTORDOWNLOADTICKETS.LASTPRINTTOOLTIP', true);
  }
  public ConstructLastDownloadToolTip (item) {
    return this.ConstructToolTip(item, 'T4.PRINTORDOWNLOADTICKETS.LASTDOWNLOADTOOLTIP', true);
  }

  public ConstructLastDuplicationToolTip (item) {
    return this.ConstructToolTip(item, 'T4.PRINTORDOWNLOADTICKETS.LASTREPRINTTOOLTIP', false);
  }

  public ConstructLastBarcodeToolTip (item) {
    return this.ConstructToolTip(item, 'T4.PRINTORDOWNLOADTICKETS.LASTBARCODETOOLTIP', true);
  }

  public ConstructToolTip (item, translationKey, addCustomer) {

    if (item === null) return '';

    let result = '';
    let date = formatDate(new Date(item.Created), 'DD-MM-Y');
    let time = formatDate(new Date(item.Created), 'HH:mm');

    result = this.translate.instant(translationKey, {date: date, time: time, itemBy: item.By});
    if (addCustomer && item.CustomerNumber !== null) {
      result += ` (${item.CustomerNumber})`;
    }

    return result;
  }

  onReprintChecked(e) {
    this.reprintChecked = e.target.checked;
    this.updateReasonDD();

  }
  updateReasonDD() {
    if (this.reprintChecked)
      this.form.controls['DuplicationReasonId'].enable();
    else
      this.form.controls['DuplicationReasonId'].disable();
  }

  // The same function is also in ManualMailDialogComponent
  getTicketSwapStatus(status: string) {
    switch (status) {
      case "Offered":
        return 'GENERIC.TICKETSWAPSTATUS.OFFERED';
      case "Accepted":
        return 'GENERIC.TICKETSWAPSTATUS.ACCEPTED';
      case "Sold":
        return 'GENERIC.TICKETSWAPSTATUS.SOLD';
      case "Withdrawn":
        return 'GENERIC.TICKETSWAPSTATUS.WITHDRAWN';
      case "Revoked":
        return 'GENERIC.TICKETSWAPSTATUS.REVOKED';
      case "PendingInvitation":
        return "GENERIC.TICKETSWAPSTATUS.PENDING-INVITATION";
      case "InvitationDeclined":
        return "GENERIC.TICKETSWAPSTATUS.INVITATION-DECLINED";
      case "Assigned":
        return "GENERIC.TICKETSWAPSTATUS.ASSIGNED";
      case "AssignmentRequired":
        return "GENERIC.TICKETSWAPSTATUS.ASSIGNMENT-REQUIRED";
      default:
        return '';
    }
  }
  
  getTicketSwapStatusClass(status: string) {
    switch (status) {
      case "Offered":
        return  ['chipbox', 'chipbox-open'];
      case "Accepted":
        return ['chipbox', 'chipbox-open'];
      case "Sold":
        return ['chipbox', 'chipbox-open'];
      case "Withdrawn":
        return ['chipbox', 'chipbox-closed'];
      case "Revoked":
        return ['chipbox', 'chipbox-closed'];
      case "PendingInvitation":
        return ['chipbox', 'chipbox-open'];
      case "InvitationDeclined":
        return ['chipbox', 'chipbox-open'];
      case "Assigned":
        return ['chipbox', 'chipbox-open'];
      case "AssignmentRequired":
        return ['chipbox', 'chipbox-open'];
      default:
        return [];
    }
  }
}
