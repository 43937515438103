import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ConfirmService} from './confirm.service';

@Injectable({providedIn: 'root'})
export class VersionCheckService {

  // this will be replaced by actual hash post-build.js
  private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';
  private interval: any = null;

  constructor(private http: HttpClient, private confirmService: ConfirmService) {
  }

  /**
   * Checks in every set frequency the version of frontend application
   * frequency - in milliseconds, defaults to 30 minutes
   */
  public initVersionCheck(url, frequency = 1000 * 60 * 30) {

    // directly check the version
    this.checkVersion(url, true);

    this.interval = setInterval(() => {
      this.checkVersion(url, true);
    }, frequency);
  }

  private checkVersion(url, notifyUser) {

    console.log('checking version');

    const fullUrl = url + '?t=' + new Date().getTime();
    console.log(fullUrl);

    const reqHeader = new HttpHeaders({'no-auth': 'true'});

    // timestamp these requests to invalidate caches
    this.http.get(fullUrl, {headers: reqHeader})
      .subscribe(
        (response: any) => {
          console.log('ok');
          const hash = response.hash;
          const hashChanged = this.hasHashChanged(this.currentHash, hash);

          // If new version, do something
          if (hashChanged) {
            this.handleUpdate(notifyUser);
          } else {
            console.log('Running version is up-to-date.');
          }
          // store the new hash so we wouldn't trigger versionChange again
          // only necessary in case you did not force refresh
          this.currentHash = hash;
        },
        (err) => {

          console.error(err, 'Could not get version');
        }
      );
  }

  handleUpdate(notifyUser: boolean): any {
    const href = window.location.href;
    if (this.interval !== null) {
      // do not continue checking when the dialog is displayed
      clearInterval(this.interval);
    }

    if (notifyUser) {

      this.confirmService.confirm({
        title: 'Update beschikbaar',
        message: 'Er is een verplichte update beschikbaar, de applicatie wordt herladen.',
        okonly: true
      }).subscribe((result) => {
        console.log('reloading..');
        window.location.href = href;
      });
    } else {
      console.log('reloading..');
      window.location.href = href;
    }
  }

  /**
   * Checks if hash has changed.
   * This file has the JS hash, if it is a different one than in the version.json
   * we are dealing with version change
   */
  private hasHashChanged(currentHash, newHash) {
    if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
      return false;
    }

    return currentHash !== newHash;
  }
}
