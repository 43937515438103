import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import PerfectScrollbar from 'perfect-scrollbar';
import {Subscription} from 'rxjs';
import {InjectComponentDirective} from '../../../directives/inject-component.directive';
import {UserMenuItem} from '../../../models/session/user-menu-item';
import {InjectComponentService} from '../../../services/inject-component.service';
import {SessionService} from '../../../services/session.service';


@Component({
  selector: 't4-page-sidenav',
  templateUrl: './page-sidenav.template.html'
})
export class PageSidenavComponent implements OnInit, AfterViewInit {

  @ViewChild(InjectComponentDirective) navHeaderInjection: InjectComponentDirective;

  private sidebarPS: PerfectScrollbar;
  menuItems: UserMenuItem[];
  expandedMnuName = '';
  expandedMnuState = true;
  expandedMnuUrl = '';

  public sessionUserSubscription: Subscription;
  public isInArchiveMode = this.sessionService.$isArchiveMode.value;


  constructor(private sessionService: SessionService,
              private router: Router,
              private injectComponentService: InjectComponentService) {
  }

  ngOnInit() {
    this.sessionUserSubscription = this.sessionService.$user.subscribe(user => {
      if (user) {
        this.menuItems = user.MenuItems.map(mi => {
          mi.cleanState = mi.State.replace(/\//g, '');
          return mi;
        });
        // get the right subitem
        const menuItem = this.menuItems.find(s => s.State && this.router.url.startsWith('/' + s.State));
        this.setExpanded(menuItem && menuItem.State ? menuItem.State : '');

        this.sessionService.$isArchiveMode.subscribe((resp) => {
          this.isInArchiveMode = resp;
        })
      }
    });
  }

  ngAfterViewInit() {
    this.injectComponentService.$componentInjected.subscribe((kvp) => {
      const navHeaderInjectionKey = 'navHeaderInjection';
      if (kvp[navHeaderInjectionKey] !== undefined) {
        const componentFactory = kvp[navHeaderInjectionKey];
        if(componentFactory !== null) {
          const componentRef = this.navHeaderInjection.viewContainerRef.createComponent(componentFactory);
        } else {
          const componentRef = this.navHeaderInjection.viewContainerRef.remove();
        }
      }
    });

    setTimeout(() => {
      this.sidebarPS = new PerfectScrollbar('#sidebar-scrollbar', {
        suppressScrollX: true
      });
    });
  }

  setExpanded(mnuName) {
    if (mnuName === this.expandedMnuName && this.expandedMnuUrl === window.location.pathname ) {
      this.expandedMnuState = !this.expandedMnuState;
    } else {
      this.expandedMnuName = mnuName.replace(/\//g, '');
      this.expandedMnuUrl = window.location.pathname;
    }
  }
}
