import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpLoaderService } from '@tymes4-shared';
import { FanOrderPaymentHistoryModel, OrderService } from '../../api';

@Component({
  selector: 'fan-payment-overview',
  templateUrl: './payment-overview.component.html'
})
export class PaymentOverviewComponent implements OnInit {

  constructor(private apploader: HttpLoaderService,
              private orderService: OrderService,
              private translate: TranslateService) { }

  // showPaymentDetails = false;

  orderId: number = null;
  // orderFailureId: number = null;

  pagedHistoryLines = [];
  lastUsedPageHistory = null;
  orderPaymentHistory : Array<FanOrderPaymentHistoryModel> = null;

  @ViewChild('historyPagerComponent') historyPagerComponent;

  ngOnInit() {
  }

  doHistoryPage(pageArgs) {
    this.setHistoryPage(pageArgs.pageNr);
  }


  setHistoryPage(pageNr: number) {
    this.lastUsedPageHistory = pageNr;
    let length = this.orderPaymentHistory.length; //we need a copy
    this.historyPagerComponent.setObjectCount(length);
    this.pagedHistoryLines = this.orderPaymentHistory.slice((pageNr - 1) * this.historyPagerComponent.pageSize, (pageNr) * this.historyPagerComponent.pageSize);
    this.historyPagerComponent.setPage (pageNr);
  }

  getPaymentStatusText(status) {
    switch (status){
      case 1: return this.translate.instant('FAN.PAYMENT-OVERVIEW.HEADER.STATUS.OK');
      case 2: return this.translate.instant('FAN.PAYMENT-OVERVIEW.HEADER.STATUS.CANCELLED');
      case 3: return this.translate.instant('FAN.PAYMENT-OVERVIEW.HEADER.STATUS.FAILED');
      case 4: return this.translate.instant('FAN.PAYMENT-OVERVIEW.HEADER.STATUS.DECLINED');
      case 5: return this.translate.instant('FAN.PAYMENT-OVERVIEW.HEADER.STATUS.PENDING');
      case 6: return this.translate.instant('FAN.PAYMENT-OVERVIEW.HEADER.STATUS.AWAITCOLLECTION');
    }
  }

  loadPaymentHistoryForOrder(orderId) {
    this.orderId = orderId;
    // this.orderFailureId = null;
    this.loadPaymentHistoryInternal();
  }

  loadPaymentHistoryInternal() {

    this.apploader.open();

    //user clicked on the paymenthistory but that has not yet been loaded
    // this.orderService.getPaymentHistory (this.orderId, this.showPaymentDetails).subscribe((data:any) => {
      this.orderService.getFanOrderPaymentHistory(this.orderId).subscribe((data:Array<FanOrderPaymentHistoryModel>) => {
      this.orderPaymentHistory = data;
      this.setHistoryPage(1);
      this.apploader.close();
    });

  }

  // onShowDetailsChange(e) {

  //   this.showPaymentDetails = e.target.checked;
  //   this.loadPaymentHistoryInternal();

  // }



}
