import * as moment from 'moment-timezone';
import { Pipe, PipeTransform } from '@angular/core';
import { SessionService } from '../services/session.service';


@Pipe({
  name: 'localDateTime'
})
export class localDateTimePipe implements PipeTransform {

  constructor(private sessionService: SessionService) {}

  transform(value: moment.MomentInput, compensateTimeZone: boolean = true): string {
    
    if (value === null || value === undefined || value === '') return null;
    
    var language = this.sessionService.getLanguage();

    if (language == null)
      console.error('localDateTimePipe cannot be used when language == null.');

    const dte = moment.utc(value); 
    
    if (compensateTimeZone) {
      return dte.tz(language.TimeZone).format(language.FormatDateTime);
    }

    return dte.tz('UTC').format(language.FormatDateTime);
  }
}