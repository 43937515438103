import { Injectable } from "@angular/core";

@Injectable()
export class FileService {

    constructor() { }

    
    getResizedImageUrl(originalUrl: string, width: number, height:number) {
        if (!originalUrl || originalUrl === null) return null;
        let resizePart = `-${ width }x${ height }`;
        return originalUrl.replace('-original', resizePart);
      }
      
    getFileExtension(fileType) {
        let ext: string;
        if (fileType == "excel") ext = '.xlsx';
        if (fileType == "pdf") ext = '.pdf';
        return ext;
    }

    makeFileNameSafe(fileName) {
        return fileName.replace(/[|&;$%@"<>()+,]/g, "");
    }
}

