import { TranslateService } from '@ngx-translate/core';
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'booleantype' })
export class BooleanTypePipe implements PipeTransform {

  constructor(private translate: TranslateService) {
  }

  transform(type: boolean) {
    if (type == true) return this.translate.instant('GENERIC.BOOLEANTYPE.YES');
    else return this.translate.instant('GENERIC.BOOLEANTYPE.NO');
  }
}