import {Component, OnDestroy, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {BaseUser} from '../../../models/session/base-user';
import {UserSalesChannel} from '../../../models/session/user-sales-channel';
import {ConfirmService} from '../../../services/confirm.service';
import {LoggingService} from '../../../services/logging.service';
import {SessionService} from '../../../services/session.service';
import {TranslateService} from '@ngx-translate/core'
import {NotificationCommunicationService} from '../../../services/notification.communication.service';

@Component({
  selector: 't4-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit, OnDestroy {

  public selectedTypeId: number = -1;
  public notificationsToDisplay: any = null;
  public newNotifications = 0;

  static readonly ROUTE_DATA_BREADCRUMB = 'breadcrumb';
  readonly home = { icon: 'pi pi-home', url: 'home' };

  breadCrumbs: any[] = [];
  routeObserver = null;
  pageTitle = '';

  public showGoToProfile: boolean = false;
  public selectedSalesChannelId: number = null;
  public selectedSeasonId: number = null;
  selectedChannelColor: string = null;
  public selectedSalesChannelName: string;
  public selectableChannels: Array<UserSalesChannel>;

  public user: BaseUser;
  public sessionUserSubscription: Subscription;
  public sessionActiveSalesChannelSubscription: Subscription;

  public notificationCenterVisible = false;
  public hasNotificationService = false;

  public hasArchiveDB = false;
  public isArchiveMode : boolean = false;

  dataValue = null;

  public archiveDates = null;

  constructor(private notificationService: NotificationCommunicationService,
    private sessionService: SessionService,
    private confirmService: ConfirmService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService) {

    this.initBreadCrumbs();

    this.routeObserver = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        const calculatedBcs = this.createBreadcrumbs(this.activatedRoute.root);

        this.initBreadCrumbs();

        for (const cbc of calculatedBcs) {
          this.breadCrumbs.push(cbc);
        }

        if (calculatedBcs.length > 0) {
          this.pageTitle = calculatedBcs[calculatedBcs.length - 1].title;
        }
      });
  }

  public showArchiveDates() {
    return this.archiveDates;
  }

  ngOnInit() {
    this.notificationService.$notifications.subscribe(data => {
      if (data) {
        this.hasNotificationService = true;
        this.notificationsToDisplay = data;
        this.newNotifications = data.New;

        if (this.notificationsToDisplay.NotificationsPerType.length > 0 && this.selectedTypeId < 0) {
          this.selectedTypeId = this.notificationsToDisplay.NotificationsPerType[0].Id;
        }
      } else {
        this.hasNotificationService = false;
      }
    });

    this.sessionActiveSalesChannelSubscription = this.sessionService.$activeSalesChannelId.subscribe((channelId) => this.onSalesChannelUpdate(channelId));

    this.sessionUserSubscription = this.sessionService.$user.subscribe((user) => {
      if (user !== undefined) {
        this.user = user;

        this.selectableChannels = user.Channels;
        this.showGoToProfile = user.ShowGoToProfile;

        if (this.selectableChannels && this.selectableChannels.length > 0) {
          this.selectedSalesChannelId = this.sessionService.$activeSalesChannelId.getValue();
          const activeSalesChannel = this.selectableChannels.filter(c => c.SalesChannelId === this.selectedSalesChannelId)[0];
          this.selectedSalesChannelName = activeSalesChannel.Name;
          this.setChannelColor(activeSalesChannel);
        }

        this.isArchiveMode = this.sessionService.$isArchiveMode.getValue();
      } else {
        console.warn('Handle user being undefined');
      }
    });

    this.sessionService.$pastAndCurrentSeasonDates.subscribe((resp) => {
      this.archiveDates = resp?.split(",");
    });

    this.sessionService.$hasArchiveDB.subscribe((resp) => {
      this.hasArchiveDB = resp;
    })
  }

  selectType(type) {
    this.selectedTypeId = type.Id;
  }

  ngOnDestroy() {
    this.routeObserver.unsubscribe();
    this.sessionUserSubscription.unsubscribe();
    this.sessionActiveSalesChannelSubscription.unsubscribe();
  }

  toggleNotificationCenter() {
    this.notificationCenterVisible = !this.notificationCenterVisible;
  }

  private initBreadCrumbs() {
    this.breadCrumbs = [];
    this.breadCrumbs.push({ label: '', url: '/dashboard', isHome: true });
  }

  public cancelLRO(lroId) {
    this.notificationService.cancelLRO(lroId);
  }

  private createBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: any[] = []) {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      let label = child.snapshot.data[PageHeaderComponent.ROUTE_DATA_BREADCRUMB];

      const noNavigation = child.snapshot.data['noNavigate'];
      let title = child.snapshot.data['title'];
      if (!title) { title = label; }

      if (label) {
        breadcrumbs.push({ label: label, url: url, isHome: false, noNavigate: noNavigation, title: title });
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  }

  goToProfile() {
    this.router.navigate(['/administration/system/my-profile']);
  }

  logOff() {
    this.sessionService.logOff();
  }

  setArchiveMode(newMode: boolean){
    this.sessionService.setArchiveMode(newMode);
  }
  getIsArchiveMode(): boolean{
    return this.sessionService.$isArchiveMode.value;
  }

  setChannel(salesChannelId: number): void {

    // Todo: move the sessionStorage getting/setting to a dedicated area.
    if (sessionStorage.getItem('pendingOrderId') !== null || sessionStorage.getItem('pendingReservationId') !== null) {
      const msg = this.translate.instant('T4.PAGE-HEADER.INPROGRESSMSG');

      this.confirmService.confirm({ title: this.translate.instant('T4.PAGE-HEADER.AREYOUSURE'), message: msg, okonly: false }).subscribe((confirmed: boolean) => {
        if (confirmed) {
          sessionStorage.removeItem('pendingOrderId');
          sessionStorage.removeItem('pendingReservationId');
          this.sessionService.setActiveSalesChannel(salesChannelId);
        }
      });
    } else {
      this.sessionService.setActiveSalesChannel(salesChannelId);
    }
  }

  onSalesChannelUpdate(channelId: number){
    if(this.selectableChannels) {
      const newSalesChannel = this.selectableChannels.filter(c => c.SalesChannelId === channelId)[0];
      this.setChannelColor(newSalesChannel);
      this.selectedSalesChannelName = newSalesChannel.Name;
    }
  }

  setChannelColor(selectedChannel: any): any {
    console.groupCollapsed('Setting channel color');

    if (selectedChannel) {
      this.selectedChannelColor = selectedChannel.HexColor;
    } else {
      this.selectedChannelColor = null;
    }

    console.groupEnd();
  }

  getBackgroundStyle() {
    let style = '';

    if (this.selectedChannelColor != null) {
      style = 'background: ' + this.selectedChannelColor;
    }

    return this.sanitizer.bypassSecurityTrustStyle(style);
  }

  isMinified = false;
  isCollapsed = false;

  toggleNavigation() {
    const body = $('body');

    if ($(window).width() >= 768) {
      if (body.hasClass('sidebar-mobile-in sidebar-mobile-out')) {
        body.removeClass('sidebar-mobile-in sidebar-mobile-out');
      }

      if (this.isMinified === false) {
        body
          .removeClass('sidebar-collapse sidebar-minified-out')
          .addClass('sidebar-minified');
        this.isMinified = true;
        this.isCollapsed = false;
      } else {
        body.removeClass('sidebar-minified');
        body.addClass('sidebar-minified-out');
        this.isMinified = false;
      }
    }

    if ($(window).width() >= 768 && $(window).width() < 992) {
      if (
        body.hasClass('sidebar-fixed') ||
        body.hasClass('sidebar-static')
      ) {
        body
          .removeClass('sidebar-collapse sidebar-minified-out')
          .addClass('sidebar-minified');
        this.isMinified = true;
      }
    }

    if ($(window).width() < 768) {
      body.css('overflow', 'hidden');
      body.prepend('<div class="mobile-sticky-body-overlay"></div>');

      $(document).on('click', '.mobile-sticky-body-overlay', function (e) {
        $(this).remove();
        const _body = $('body');
        _body.removeClass('sidebar-mobile-in').addClass('sidebar-mobile-out');
        _body.css('overflow', 'auto');
      });

      const min = 'sidebar-mobile-in';
      const min_out = 'sidebar-mobile-out';

      body.hasClass(min) ?
        body
          .removeClass(min)
          .addClass(min_out)
        : body
          .addClass(min)
          .removeClass(min_out);
    }
  }

  downloadAttachment (notificationId: number) {
    this.notificationService.downloadAttachment(notificationId);
  }

  createContinuation(notificationId: number) {
    this.notificationService.triggerContinuation(notificationId);
  }

  showAdditionalInformation (notificationId: number) {
    this.notificationService.$notificationShowMoreInformation.next(notificationId);
  }

  getDetailText(n: any) {
    return n.LROIsQueued || n.NotificationStatusCode == 'Canceled' ? n.NotificationStatusTranslationCode : n.DetailText;
  }
}
