import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MyTicketStatusEnum } from '../../enums/my-ticket-status.enum';

@Component({
  selector: 't4-ticket-iba-status',
  templateUrl: './ticket-iba-status.component.html',
  styleUrls: ['./ticket-iba-status.component.scss']
})
export class TicketIBAStatusComponent implements OnInit {
  
  public MyTicketStatusEnum = MyTicketStatusEnum;
  
  @Input() ticket: any = null;

  ngOnInit() {
  }
}
