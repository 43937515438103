import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {DropdownMenuItemComponent} from '../components/dropdown-menu-item/dropdown-menu-item.component';
import {DropdownMenuComponent} from '../components/dropdown-menu/dropdown-menu.component';

@NgModule({
  declarations: [
    DropdownMenuComponent,
    DropdownMenuItemComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
  ],
  exports: [
    DropdownMenuComponent,
    DropdownMenuItemComponent,
  ],
  providers: [
  ],
  entryComponents: [
  ]
})
export class DropdownMenuModule {
}
