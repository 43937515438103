import { NgApexchartsModule } from 'ng-apexcharts';
import { DashSmallChartComponent } from '../components/charts/dash-smallchart/dash-smallchart.component';
import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";

@NgModule({
    declarations: [DashSmallChartComponent],
    imports: [
        CommonModule,
        NgApexchartsModule
    ],
    exports: [DashSmallChartComponent],
    providers: [
    ],
    entryComponents: []
})
export class T4ChartsModule {}