<div [class.border]="renderInCard === true" class="rounded bg-white" [class.email-wrapper]="noSpacing === false">
  <div class="row no-gutters justify-content-center">

    <!--    &lt;!&ndash; END FILTER PART &ndash;&gt;-->
    <div class="col-lg-12">
      <div class="email-right-column" style="padding-bottom: 0!important" [class.p-4]="noSpacing === false" [class.p-xl-5]="noSpacing === false">
        <!-- Email Right Header -->
        <div class="email-right-header" *ngIf="displaySearch || importantItemFilterTemplate || listActionsTemplate">
          <!-- head left option -->
          <form [formGroup]="form">
            <div class="head-left-options">
              <ng-container *ngIf="displaySearch">
                <div class="form-group mg-b-0">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text bd-t-0 bd-l-0"><i class="fas fa-search"></i> </span>
                    </div>
                    <input formControlName="SearchText" (keydown.enter)="updateFilter($event)" type="text"
                           style="background-color: #f5f6fa;" class="form-control bd-t-0 bd-r-0 bd-l-0"
                           placeholder="{{'T4.LIST-VIEW.SEARCHINLIST' | translate}}">
                  </div>
                </div>
              </ng-container>
              <div *ngIf="importantItemFilterTemplate">
                <ng-container *ngTemplateOutlet="importantItemFilterTemplate; context: importantFilterValues">
                </ng-container>
              </div>
            </div>
          </form>
          <!-- head right option -->
          <div class="head-right-options">
            <ng-container *ngTemplateOutlet="listActionsTemplate">
            </ng-container>
          </div>
        </div>
      </div>

      <div class="col-lg-12" style="padding: 1.5625rem !important; padding-top:0 !important">
        <tbody style="display:block">
        <ng-container *ngTemplateOutlet="additionalItemTemplate;"></ng-container>
        <ng-container *ngIf="dataItems !== null && dataItems.length > 0; else noItems">


          <tr *ngFor="let item of dataItems;" (click)="doRowClick($event, item)" style="display:block">
            <ng-container *ngTemplateOutlet="listItemTemplate; context: {$implicit: item}"></ng-container>
          </tr>

        </ng-container>

        <ng-template #noItems>
          <tr style="display:block">
            <!-- <td *ngIf="multiSelectMode"></td> -->
            <td *ngIf="searchPerformed === true" [attr.colspan]="listHeaders.length "
                [innerHTML]="noItemText | translate"></td>
            <td *ngIf="searchPerformed === false" [attr.colspan]="listHeaders.length "
                [innerHTML]="'LISTVIEW.STARTSEARCH' | translate"></td>
          </tr>
        </ng-template>

        </tbody>
      </div>
      <t4-pager [pageSize]="pageSize" [listCounts]="listCounts" [selectCount]="null"
                (onPage)="doPage($event)" [resultsCapped]="resultsCapped" #pagerComponent></t4-pager>
    </div>
  </div>
</div>
