import { HttpContext } from '@angular/common/http';
import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {
  DialogHelper,
  DialogWidth,
  HttpLoaderService,
  PrintOrDownloadTicketsComponent,
  ProductTypeEnum,
  ReplaceOnEmptyPipe
} from '@tymes4-shared';
import * as FileSaver from 'file-saver';
import { FanOrderDetailsView, FanOrderPrintDetails, OrderService, TicketDownloadArgs, TicketService } from '../../api';


@Component({
  selector: 'fan-order-details',
  templateUrl: './order-details.component.html',
  providers: [ReplaceOnEmptyPipe]
})
export class OrderDetailsComponent implements OnInit {
  public ProductTypeEnum = ProductTypeEnum;
  public isSecondaryShop: boolean = false;

  public orderDetails: FanOrderDetailsView = null;
  public isIbaActive: boolean = false;

  @ViewChild('paymentOverview') paymentOverview;

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
              public dialogRef: MatDialogRef<OrderDetailsComponent>,
              private dialog: MatDialog,
              private apploader: HttpLoaderService,
              private ticketService: TicketService,
              private orderService: OrderService,
              private translate: TranslateService,
              ) {
  }

  ngOnInit() {
    this.orderDetails = this.passedData.order;

    //As this is fan management, the identification state of customers is turned off
    this.isIbaActive = false;
  }

  onTabChange(e) {
    if (e.selectedTab.id === 'payment-history') {
      this.paymentOverview.loadPaymentHistoryForOrder(this.orderDetails.Id);
    }
  }

  addPropertiesToSubscriptionLines(order: any) {
    for (const subscription of order.SubscriptionViews) {
      // console.log(subscription)
      const orderLine = order.OrderLines.filter(ol => ol.Id === subscription.OrderLineId)[0];
      if (orderLine) {
        subscription.EventDate = orderLine.EventDate;
        subscription.ParentSectionName = orderLine.ParentSectionName;
        subscription.TicketCustomer = orderLine.TicketCustomer;
        subscription.PassePartoutName = orderLine.PassePartoutName;
        subscription.RowNumber = orderLine.RowNumber;
        subscription.SeatNumber = orderLine.SeatNumber;
        subscription.Total = orderLine.Total;
      }
    }
    return order;
  }

displayDownloadDialog() {
  this.apploader.open();

  this.orderService.getFanOrderPrintDetails(this.orderDetails.Id).subscribe((data: FanOrderPrintDetails) => {
    this.apploader.close();

    const dialogOptions = DialogHelper.GetDialogOptions(DialogWidth.lg, {
      title: this.translate.instant('FAN.ORDERDETAILS.DOWNLOADTICKETS.HEADER'),
      payload: data, mode: 'download', allowSalesChannelSelect: false, isIbaActive: this.isIbaActive
    });

    const dialogRef: MatDialogRef<any> = this.dialog.open(PrintOrDownloadTicketsComponent, dialogOptions);

    dialogRef.afterClosed()
      .subscribe(submitForm => {

          if (submitForm === null) {
            //nothing to do. The download is closed without pressing download
          } else {

            this.apploader.open();

            const args : TicketDownloadArgs = {
              OrderId: this.orderDetails.Id,
              SelectedEventPlacementIds: submitForm.SelectedEventplacementIds,
              isDuplicate: submitForm.IsDuplicate,
              DuplicationReasonId: submitForm.DuplicationReasonId,
              SalesChannelId: submitForm.SalesChannelId,
            };

            //FDP: Download in background is not supported in Fanmanagement by choice.
            
            //to the download
            this.ticketService.downloadTicket(args).subscribe((data: any) => {
              this.apploader.close();

              //  console.log("download data received OK.");
              FileSaver.saveAs(data, `E-tickets_order_${this.orderDetails.Id}.zip`);
            });

          }
        }
      );
  });

  return false;

}

}
