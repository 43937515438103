
import { EditEventProductComponent } from './dialogs/edit-event-product/edit-event-product.component';
import { EventProductStockMutationsComponent } from './dialogs/event-product-stockmutations/event-product-stockmutations.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { NgSelectModule } from '@ng-select/ng-select';
import { AddResellerContactComponent } from './dialogs/add-reseller-contact/add-reseller-contact.component';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from "@angular/core";
import { OrderDetailsComponent } from './dialogs/order-details/order-details.component';
import { PaymentOverviewComponent } from './components/payment-overview/payment-overview.component';
import { FormExtensionModule, LayoutsModule, ListViewModule, LoaderModule, LoggingService, PipesModule, TabsModule, DatesModule, OrderDetailsModule, PageModule, PagerModule, HttpLoaderService, FileService, StockMutationsModule, ControlsModule } from '@tymes4-shared';
import { TranslateModule } from '@ngx-translate/core';

const classesToInclude = [
    OrderDetailsComponent,
    PaymentOverviewComponent,
    AddResellerContactComponent,
    EventProductStockMutationsComponent,
    EditEventProductComponent,
];

@NgModule({
    imports: [
        CommonModule,
        FormExtensionModule,
        LayoutsModule,
        ListViewModule,
        LoaderModule,
        PipesModule,
        TabsModule,
        DatesModule,
        OrderDetailsModule,        
        TranslateModule,
        PageModule,
        PagerModule,
        MatDatepickerModule,
        MatSelectModule,
        NgSelectModule,
        FormsModule,
        MatDialogModule,
        ReactiveFormsModule,
        StockMutationsModule,
        ControlsModule
    ],
    providers: [
        LoggingService,
        HttpLoaderService,
        DatePipe,
        FileService
    ],
    declarations: classesToInclude,
    entryComponents: [
        OrderDetailsComponent,
        AddResellerContactComponent,
        EditEventProductComponent,
        EventProductStockMutationsComponent
    ],
    exports: classesToInclude
})
export class SharedModule {}
