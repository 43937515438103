import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {PagerIrregularSizeComponent} from '../components/pager-irregular-size/pager-irregular-size.component';
import {PagerComponent} from '../components/pager/pager.component';
import {PagerService} from '../services/pager.service';

@NgModule({
  declarations: [
    PagerComponent,
    PagerIrregularSizeComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
  ],
  exports: [
    PagerComponent,
    PagerIrregularSizeComponent
  ],
  providers: [
    PagerService
  ],
  entryComponents: []
})
export class PagerModule {
}
