import { LoggingService } from './../services/logging.service';
import { CommonModule } from '@angular/common';
import { ListViewModule } from './list-view.module';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { StockMutationsComponent } from './../components/stock-mutations/stock-mutations.component';
import { PipesModule } from './pipes.module';

const classesToInclude = [
    StockMutationsComponent
]

@NgModule({
    declarations: classesToInclude,
    imports: [
        CommonModule,
        TranslateModule,
        ListViewModule, 
        PipesModule
    ],
    exports: classesToInclude,
    providers: [
        TranslateService,
        LoggingService
    ]
})
export class StockMutationsModule {}