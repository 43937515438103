export class DialogHelper {

    public static GetDialogOptions (width: DialogWidth, data: any) {

        let dialogWidth = '100vw';
    
        switch(width) {
            case DialogWidth.md: {
                dialogWidth = '720px';
                break;
            }
            case DialogWidth.lg: {
                dialogWidth = '1024px';
                break;
            }

            case DialogWidth.xlg: {
                dialogWidth = '1280px';
                break;
            }

            case DialogWidth.xxlg: {
                dialogWidth = '1700px';
                break;
            }

            case DialogWidth.full: {
                dialogWidth = '95vw';
                break;
            }
            
        }
        return {
            maxWidth: '95vw',
            width: dialogWidth,
            disableClose: true,
            data: data
        }
    }

}

export enum DialogWidth {
    md = 1, 
    lg = 2, 
    xlg = 3, 
    full = 4,
    xxlg = 5
}