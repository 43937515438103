import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {PagerService} from '../../services/pager.service';


@Component({
  selector: 't4-pager',
  templateUrl: './pager.component.html',
  styleUrls: ['./pager.component.scss']
})
export class PagerComponent implements OnInit {
  constructor(private pagerService: PagerService,
    private translate : TranslateService) { }

  @Input('pageSize') pageSize = 7;
  @Input('selectCount') selectCount: number;
  @Input('listCounts') listCounts = true;
  @Input('resultsCapped') resultsCapped = false;

  @Output() onPage = new EventEmitter<object>();

  totalObjectCount: number;
  public pager: any = {}; 
  public currentPageNr = 1;

  ngOnInit() {
  }

  doPage (pageNr) {
    this.currentPageNr = pageNr;

    this.onPage.emit({
      pageNr
    });
  }

  public getPaginationText() {
    const firstItem = ((this.currentPageNr - 1) * this.pageSize) + 1;
    const lastItem = Math.min(this.totalObjectCount, firstItem + this.pageSize - 1);

    let text = this.translate.instant('T4.PAGER.ITEMSOF', { firstItem: firstItem, lastItem: lastItem, totalObjectCount: this.totalObjectCount});

    if (this.selectCount && this.selectCount !== null)
      text += this.translate.instant('T4.PAGER.ITEMSSELECTED', { selectCount: this.selectCount});
    
      
    return text;
  }

  public setObjectCount(count: number) {
    this.totalObjectCount = count;
  }

  public setPage(page: number) {
    // get pager object from service

    this.pager = this.pagerService.getPager(this.totalObjectCount, page, this.pageSize);
  }
}
