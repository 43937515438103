import { SearchDataRequest } from "./../models/http/search-data-request";

export class ListViewHelper {
    public static MapSearchDataFiltersToDictionary(sdr: SearchDataRequest) : {[key: string]: string} {
        let additionalFilters : {[key: string]: string} = {};
        for (const key of Object.keys(sdr.staticFilters)) {
            additionalFilters[key] = sdr.staticFilters[key];
        }
        for (const key of Object.keys(sdr.importantFilters)) {
            additionalFilters[key] = sdr.importantFilters[key];
        }
        for (const key of Object.keys(sdr.searchFilters)) {
            additionalFilters[key] = sdr.searchFilters[key];
        }

        return additionalFilters;
    }
}