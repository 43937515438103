import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ResolveEnd, ResolveStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import PerfectScrollbar from 'perfect-scrollbar';
import {Subscription} from 'rxjs';


@Component({
  selector: 't4-admin-layout',
  templateUrl: './admin-layout.template.html'
})
export class AdminLayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  private mainPS: PerfectScrollbar;

  public isModuleLoading = false;
  private moduleLoaderSub: Subscription;
  public isArchiveMode: boolean = (sessionStorage.getItem('userIsArchiveMode') == "true");
  

  public layoutConf: any = {};

  constructor(public translate: TranslateService, private router: Router) {
  }

  ngOnInit() {
    // FOR MODULE LOADER FLAG
    this.moduleLoaderSub = this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart || event instanceof ResolveStart) {
        this.isModuleLoading = true;
      }
      if (event instanceof RouteConfigLoadEnd || event instanceof ResolveEnd) {
        this.isModuleLoading = false;
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.mainPS = new PerfectScrollbar('#main-scrollbar', {
        suppressScrollX: true
      });
    });
  }

  ngOnDestroy() {
    if (this.moduleLoaderSub) {
      this.moduleLoaderSub.unsubscribe();
    }
  }
}
