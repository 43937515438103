import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {LoaderComponent} from '../components/loader/loader.component';
import { Observable } from 'rxjs';

@Injectable()
export class HttpLoaderService {
  dialogRef: MatDialogRef<LoaderComponent>;
  constructor(private dialog: MatDialog) { }

  private timer;

  public open() {
    this.openWithMessage(null);
  }

  public openDirect(message?: string) {
    this.openDelayed(message);
  }

  public openWithMessage(message?: string) {
    if (this.timer == null) {
      // display the spinner of the operation takes longer than a second
      this.timer = setTimeout(() => this.openDelayed(message) , 1000);
    }
  }

  public isOpen() {
    return (this.timer !== null || this.dialogRef);
  }

  private openDelayed(message?: string) {

    this.dialogRef = this.dialog.open(LoaderComponent, {disableClose: true});
    this.dialogRef.updateSize('200px');
    if (message) {
      this.dialogRef.componentInstance.title = message;
    }
    return this.dialogRef.afterClosed();
}
  public close() {

    if (this.timer != null) {
      // cancel the upcoming spinner
      clearTimeout(this.timer);
      this.timer = null;
    }

    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
}
