import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 't4-upload-message',
  templateUrl: './upload-message.component.html'
})
export class UploadMessageComponent implements OnInit {
    public errorText = '';

    constructor(
    public dialogRef: MatDialogRef<UploadMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService
    ) {
    if (data.cancelonly === undefined) {
      data.cancelonly = false;
    }
    if (data.okonly === undefined) {
      data.okonly = false;
    }
    if(data.isWarning === undefined) {
      data.isWarning = false;
    }
  }

  ngOnInit() {
    if (!this.data.errors || !this.data.message || !this.data.messageAdditional) {
        return;
    }

    if(this.data.isWarning) {
      this.errorText = this.buildUploadWarningText(this.data.errors, this.data.message, this.data.messageAdditional);
    } else {
      this.errorText = this.buildUploadErrorText(this.data.errors, this.data.message, this.data.messageAdditional);
    }
    
  }

  buildUploadErrorText(errors, message, addtionalMessage) {
    let result = '<div><b>' + this.translate.instant(message) + '</b></div><br />';
    let errorCounter = 0;
    for (const e of errors) {
      if (errorCounter >= 5) {
        result += `<div style='color:red;'><span><i class="fas fa-exclamation-circle"></i>&nbsp; ` + this.translate.instant(addtionalMessage, {remainingErrors: String(errors.length - errorCounter)}) + `</span></div><br />`;
        break;
      }

      result += `<div style='color:red;'><span><i class="fas fa-exclamation-circle"></i>&nbsp;` + this.translate.instant(e) + `</span></div><br />`;
      errorCounter++;
    }
    return result;
  }

  buildUploadWarningText(warnings, message, addtionalMessage) {
    let result = '<div><b>' + this.translate.instant(message) + '</b></div><br />';
    let warningCounter = 0;
    for (const e of warnings) {
      if (warningCounter >= 5) {
        result += `<div style='color:orange;'><span><i class="fas fa-exclamation-triangle"></i>&nbsp; ` + this.translate.instant(addtionalMessage, {remainingWarnings: String(warnings.length - warningCounter)}) + `</span></div><br />`;
        break;
      }

      result += `<div style='color:orange;'><span><i class="fas fa-exclamation-triangle"></i>&nbsp;` + this.translate.instant(e) + `</span></div><br />`;
      warningCounter++;
    }
    return result;
  }
}
