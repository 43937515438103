import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ListViewModule } from './list-view.module';

import { PagerModule } from './pager.module';
import { PageModule } from './page.module';
// import { PrintOrDownloadTicketsComponent } from '../components/order-details/print-or-download-tickets/print-or-download-tickets.component';
import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from './pipes.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { SecondaryOrderHeaderComponent } from '../components/secondary-order-details/secondary-order-header/secondary-order-header.component';
import { SecondaryOrderLinesComponent } from '../components/secondary-order-details/secondary-order-lines/secondary-order-lines.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { ControlsModule } from './controls.module'


const classesToInclude = [
    // PrintOrDownloadTicketsComponent,
    //OrderHeaderComponent,
    //OrderLinesComponent,
    SecondaryOrderHeaderComponent,
    SecondaryOrderLinesComponent
       
 
]

@NgModule({
    declarations: classesToInclude,
    imports: [
        CommonModule, 
        MatDialogModule, 
        PageModule,     
        PagerModule,
        PipesModule,
        NgSelectModule,
        MatSelectModule,
        ListViewModule,       
        TranslateModule,
        ControlsModule,
    ],
    exports: classesToInclude,
    providers: [
        TranslateService
    ],
    entryComponents: []
})
export class SecondaryOrderDetailsModule {}