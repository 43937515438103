<div class="modal-dialog" role="document">
    <div class="modal-content bd-0">
      <div class="modal-header pd-y-20 pd-x-25">
        <h6 class="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">{{ data.title | translate }}</h6>
        <button type="button" class="close" (click)="dialogRef.close(false);" aria-label="Sluiten">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-5">
        <p class="mg-b-5" [innerHtml]="errorText | safe: 'html'"></p>
      </div>
  
      <div class="modal-footer">
        <div class="col-6">
          <button type="button" class="btn btn-block btn-primary" (click)="dialogRef.close(true)" *ngIf="data.cancelonly.valueOf() === false">{{ 'BTN.OK' | translate }}</button>
        </div>
        <div class="col-6">
          <button type="button" class="btn btn-block btn-secondary" (click)="dialogRef.close(false);" *ngIf="data.okonly.valueOf() === false">{{ 'BTN.CANCEL' | translate }}</button>
        </div>
      </div>
  
    </div>
  </div>
  