
import * as moment from 'moment-timezone';

import { Pipe, PipeTransform } from '@angular/core';
import { SessionService } from '../services/session.service';

@Pipe({
  name: 'localTime'
})
export class localTimePipe implements PipeTransform {

  constructor(private sessionService: SessionService) {}
  
  transform(value: Date, compensateTimeZone: boolean = true): string {

    if (value === null || value === undefined) return null;

    var language = this.sessionService.getLanguage();

    if (language == null)
      console.error('localTimePipe cannot be used when language == null.');

    const dte = moment.utc(value); 
  
    if (compensateTimeZone) {
      return dte.tz(language.TimeZone).format(language.FormatTime);
    }

    return dte.tz('UTC').format(language.FormatTime);
  }
}