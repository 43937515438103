import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 't4-dropdown-menu-item',
  templateUrl: 'dropdown-menu-item.component.html'
})

export class DropdownMenuItemComponent implements OnInit {
  @Input() cssClass: string;
  @Input() label: string;
  @Input() isDisabled: boolean;

  @Output() action = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  public handleAction(ev) {
    ev.preventDefault();
    this.action.emit();
  }
}
