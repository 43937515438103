import {Directive, ElementRef, OnInit, Input, ChangeDetectorRef, forwardRef, Output, EventEmitter} from '@angular/core';
import * as JQueryUI from 'jquery-ui';
declare const $: any; // avoid the error on $(this.eInput).datepicker();
import datepickerFactory from 'jquery-datepicker';

import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

import * as momentImported from 'moment'; 
import * as moment from 'moment'; 
//const moment = momentImported;

// Documentation: https://api.jqueryui.com/datepicker/


@Directive({selector: '[datepicker]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR, 
      useExisting: forwardRef(() => DatePickerDirective),
      multi: true
    }
  ]})
export class DatePickerDirective implements OnInit, ControlValueAccessor {

  @Input() changeYear = true;
  @Input() changeMonth = true;
  @Input() beforeToday = false;
  @Input() afterToday = false;
  @Input() yearRange: string = null;
  @Input() afterAndNotToday = false;
 
  private datePickerEl: any = null;
  private _value: any = null;

  @Output() change = new EventEmitter<any>();

  onChange = (val: momentImported.Moment) => {};
  onTouched = () => {};

  constructor(private el: ElementRef, private cdr: ChangeDetectorRef) {
    // Verify if the datepicker has been initialised already
    if (!$.fn.datepicker) {
      datepickerFactory($);
    }
  }

  ngOnInit() {

    const options: JQueryUI.DatepickerOptions = {
      dateFormat: 'dd-mm-yy',
      changeYear: this.changeYear,
      changeMonth: this.changeMonth,
      onSelect: (dateText, inst) => this.selectionChanged(dateText, inst),
      onClose: (dateText, inst) => {this.selectionChanged(inst.input.val(), inst); this.onTouched();},
    };

    if(this.yearRange) {
      options.yearRange = this.yearRange;
    }

    if (this.beforeToday) {
      options.maxDate = new Date();
      options.yearRange = this.yearRange ? this.yearRange : `1900:${options.maxDate.getFullYear()}`;
    }

    if (this.afterToday) {
      options.minDate = new Date();
    }

    if (this.afterAndNotToday) {
      options.minDate = new Date(Date.now() + ( 3600 * 1000 * 24));
    }
    this.datePickerEl = $(this.el.nativeElement).datepicker(options);

    this.cdr.detectChanges();
  }

  selectionChanged(dateText: string, inst) {
    let dateVal = null;
    if (dateText && dateText.length > 0) {
      dateVal = moment.utc(dateText, 'DD-MM-YYYY');
    }
    this.writeValue(dateVal);
    this.change.emit(dateVal);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
    if (this._value) {
      this.onChange(this._value);
    }
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(val: any): void {

    if (this.datePickerEl && (val instanceof Date)) {
      this.datePickerEl[0].value = moment.utc(val).format('DD-MM-YYYY');
      val = this.datePickerEl[0].value;
    } 

    else if (moment.isMoment(val)) {
      this.datePickerEl[0].value = val.format('DD-MM-YYYY');
    }
    if (typeof val === 'string') {

      if (val.length > 10) {
        this.datePickerEl[0].value = moment.utc(val).format('DD-MM-YYYY');
      }
      else if (val !== '') { //fdp: fix, empty string need not to be parsed by moment.js
        this.datePickerEl[0].value = moment.utc(val, 'DD-MM-YYYY').format('DD-MM-YYYY');
      }
    }
    if (this.onChange) {
      this.onChange(val);
      if(val == undefined){
        this.datePickerEl[0].value = '';
      }
    } else {
      this._value = val;
    }
    this.cdr.detectChanges();
  }

  setDisabledState(isDisabled: boolean): void {
    if( this.datePickerEl[0])
      this.datePickerEl[0].disabled = isDisabled;
  }
}
