import { CommonModule } from '@angular/common';
import { LoaderModule } from './loader.module';
import { CardComponent } from '../components/card/card.component';
import { NgModule } from "@angular/core";

@NgModule({
    declarations: [CardComponent],
    imports: [
        CommonModule,
        LoaderModule
    ],
    exports: [CardComponent],
    providers: [
    ],
    entryComponents: []
})
export class CardModule {}