import {Component, Input, OnInit} from '@angular/core';

@Component({
 selector: 't4-dropdown-menu',
 templateUrl: 'dropdown-menu.component.html'
})

export class DropdownMenuComponent implements OnInit {

  @Input() type: 'button'|'icon';
  @Input() label: string;

 constructor() { }

 ngOnInit() { }
}
