import { TranslateService } from '@ngx-translate/core';
import {HttpResponse} from '@angular/common/http';
import {Component, Inject, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {HttpLoaderService, KeyValuePair, SnackbarService} from '@tymes4-shared';
import {concat, Observable, of, Subject} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, map, skipWhile, switchMap, tap} from 'rxjs/operators';
import { ResellerService } from '../../api/api/reseller.service';
import { ContactService } from '../../api/api/contact.service';
import { FanContactModelPagedResult } from '../../api/model/fanContactModelPagedResult';

@Component({
  selector: 'fan-add-reseller-contact',
  templateUrl: './add-reseller-contact.component.html',
})

export class AddResellerContactComponent implements OnInit {

  public form: FormGroup;
  public saving = false;

  resellerId: number;
  public resellerContact: any = {};
  isLoadingContact: boolean = false;
  public isNewResellerContact: boolean = false;

  contactsLoading = false;
  contacts: Observable<unknown>;
  contactsInput$ = new Subject<string>();

  validationMessages = { };

  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    private loader: HttpLoaderService,
    public dialogRef: MatDialogRef<AddResellerContactComponent>,
    private resellerService: ResellerService,
    private contactService: ContactService,
    private snackbar: SnackbarService,
    private translate: TranslateService
  ) { }

  private requiredFields: KeyValuePair<boolean> = null;

  isRequiredField(field: string, targetForm: FormGroup = null): boolean {
      if (this.requiredFields === null)
        this.requiredFields = {};

      if (this.requiredFields[field] === null || this.requiredFields[field] === undefined) {
        const form = targetForm ? targetForm : this.form;
        const formField = form.get(field);
        if (!formField.validator) {
          return false;
        }

        const validator = formField.validator({} as AbstractControl);
        this.requiredFields[field] = validator && validator.required;

        formField.validator(formField);
      }
      return this.requiredFields[field];
    }
 

  ngOnInit() {
    this.resellerContact = {};
   
    var contactCtrl = new FormControl('', [Validators.required]);
    this.form = new FormGroup({
      ContactId: contactCtrl
    });

    this.loadContacts();

    this.form.patchValue(this.resellerContact);
  }

  private getItemDisplayText(item): string {
    
    if (!item || item === null || item.Name === null) return '';

    let name = item.FirstName + ' ';
    if (item.MiddleName) name += item.MiddleName + ' ';
    name += item.LastName;

    let email = item.Email ? item.Email : '-';

    return `${name} (${item.CustomerNumber} / ${email})`;

  }

  private loadContacts(contact: any = null) {
    const prefilled = contact ? [contact] : [];
    this.contacts = concat(
        of(prefilled), // default items
        this.contactsInput$.pipe(
            debounceTime(500),
            skipWhile(x => x.length < 3),
            distinctUntilChanged(),
            tap(() => { this.contactsLoading = true; }),
            switchMap(term => this.contactService.searchContacts(term, 1, 100).pipe(
                catchError(() => of([])), // empty list on error
                map((f: FanContactModelPagedResult) => f.Records),
                tap(() => { this.contactsLoading = false; })
            ))
        )
    );
  }

  IsValidAndDirty() {
    return this.form.valid && this.form.dirty;
  }

  submit() {
    const data = this.form.value;
    this.loader.open();
    this.resellerService.addFanContact(data.ContactId).subscribe(() => {
      this.loader.close();
      this.snackbar.open(this.translate.instant('FAN.ADDRESELLERCONTACT.SUCCESS.MSG'));
    });

    this.dialogRef.close(true);
  }
}
