import { AbstractControl } from '@angular/forms';
import { Component, OnInit, Inject, Input } from '@angular/core';

@Component({
  selector: 't4-page-info',
  template: `
  <div class="mt-3 alert alert-info" role="alert">
    <i class="fa fa-info-circle"></i>
    {{ infoText | translate : infoParams }}
  </div>     
  `
})
export class PageInfoComponent implements OnInit {

  @Input() showDivider;

  constructor() { }

  public infoText = '';
  public infoParams = {};


  @Input()
  set info(txt: string) {
    this.infoText = txt;
  }

  @Input()
  set parameters(params: any)
  {
    if (params != null)
      this.infoParams = params;
  }

  ngOnInit() {
  }
}