import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 't4-loader',
  templateUrl: 'loader.component.html'
})

export class LoaderComponent implements OnInit {

  @Input() size = 30;

  public foldingCubeSize: any;
  public title = 'LOADER.GENERIC.MESSAGE';

  constructor() {
  }

  ngOnInit() {
    this.foldingCubeSize = {
      'margin': `${this.size}px auto`,
      'width': `${this.size}px`,
      'height': `${this.size}px`
    };
  }
}
