import { TranslateService } from '@ngx-translate/core';
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'replaceOnEmpty'})

export class ReplaceOnEmptyPipe implements PipeTransform {
  constructor(private translate: TranslateService) { }

  transform(text: string, replace: string = '-') {

    if (text == null || text.length < 1) {
      return this.translate.instant(replace);
    }

    return text;
  }
}
