import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'fan-empty-with-message',
  templateUrl: './empty-with-message.component.html',
  styleUrls: ['./empty-with-message.component.scss']
})
export class EmptyWithMessageComponent implements OnInit {

  public title: string;
  public message: string;

  constructor(private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(data => {
      this.title = data.title;
      this.message = data.message;
    });
  }

}
