import {NgModule} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateTimeControlComponent } from '../controls/date-time-control/date-time-control.component';
import { CustomerIdentificationControlComponent } from '../controls/customer-identification-control/customer-identification-control.component'
import { DatesModule } from './dates.module';

@NgModule({
  declarations: [
    DateTimeControlComponent, 
    CustomerIdentificationControlComponent
  ],
  imports: [
    CommonModule, 
    DatesModule, 
    FormsModule,
    ReactiveFormsModule,
    TranslateModule
  ],
  exports: [
    DateTimeControlComponent,
    CustomerIdentificationControlComponent
  ], 
  providers: [
    TranslateService
  ]
}) 
export class ControlsModule { }
