
<div class="status-sm-chart-01">
  <apx-chart  [chart]="chart" *ngIf="series"
              [plotOptions]="plotOptions"
              [dataLabels]="dataLabels"
              [stroke]="stroke"
              [series]="series"
              [fill]="fill"
              [colors]="colors"
              [tooltip]="toolTip">
  </apx-chart>
</div>



