import {LoggingService} from './logging.service';
import {KeyValuePair} from '../models/common/key-value-pair';
import {BehaviorSubject, Observable} from 'rxjs';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import {ComponentFactory, Injectable} from '@angular/core';
import {ConfirmComponent} from '../components/confirm/confirm.component';
import {ConfirmData} from '../models/common/confirm-data';


@Injectable({providedIn: 'root'})
export class InjectComponentService {

  private injectedComponents: KeyValuePair<ComponentFactory<any>> = {};
  public $componentInjected = new BehaviorSubject<KeyValuePair<ComponentFactory<any>>>(null);

  constructor(private logger: LoggingService) { }

  injectComponent(key: string, factory: ComponentFactory<any>) {
    this.logger.log(`Component injected as ${key}`);
    this.injectedComponents[key] = factory;

    this.$componentInjected.next({[key]: factory});
  }
}
