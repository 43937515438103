import { ListViewComponent } from './../../list-view/list-view.component';
import { ProductTypeEnum } from '../../../enums/product-type.enum';
import { IdentificationCustomer } from '../../../controls/customer-identification-control/customer-identification-control.component';
import { ListViewSelectionMode } from './../../../enums/list-view-selection-mode.enum';
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { PagedResult } from './../../../models/list-view/pagedResult';

@Component({
    selector: 't4-original-order-lines',
    templateUrl: './original-order-lines.component.html',
    styleUrls: ['./original-order-lines.component.scss']
})
export class OriginalOrderLinesComponent implements OnInit {
    public ProductTypeEnum = ProductTypeEnum;

    @Input() public orderLines: Array<any>;
    @Input() public isIbaActive: boolean;

    @ViewChild(ListViewComponent) listVw : ListViewComponent;

    public listOptions = {
        search: false,
        showBarcodeSearch: false,
        pageSize: 7 ,
        noItemsText: 'T4.ORDERDETAILS.ORDERLINES.NOLINES',
        selectionMode: ListViewSelectionMode.None,
        disableSort: true,
        renderInCard: false,
        noSpacing: true,
        headers: [
            {sortField: null, label: 'T4.SECONDARY.ORDERDETAILS.HEADER.PRODUCT', hideOn: null },
            {sortField: null, label: 'T4.SECONDARY.ORDERDETAILS.HEADER.SECTIONROWSEAT', hideOn: null, width: 150},
            {sortField: null, label: 'T4.SECONDARY.ORDERDETAILS.HEADER.ORIGINALFROM', hideOn: null, width: 150},
            {sortField: null, label: 'T4.SECONDARY.ORDERDETAILS.HEADER.ORIGINALORDER', hideOn: null, width: 200},
            {sortField: null, label: 'T4.SECONDARY.ORDERDETAILS.HEADER.ORIGINALSALEDATE', hideOn: null, width: 150}
        ]
      };

    public orderLinesLength;
    public pagedOrderLines;
    public hasTicketStatus: boolean = false;
    private checkedTicketStatus: boolean = false;

    constructor(
    ) { }

    doPage($event) {
        this.pagedOrderLines = this.orderLines.slice(($event.pageNr - 1) * $event.pageSize, ($event.pageNr) * $event.pageSize);

        let d : PagedResult = {
            TotalRecords: this.orderLinesLength,
            Records: this.pagedOrderLines
        }
        this.listVw.Data = d;

        if(!this.checkedTicketStatus){
            this.checkedTicketStatus = true;
            this.listVw.setOptions(this.listOptions)
            this.listVw.refreshData();
        }
    }

    ngOnInit(): void {
        this.orderLines = this.orderLines.filter(ol => ol.ProductType !== ProductTypeEnum.Discount);

        this.orderLinesLength = this.orderLines.length;
    }

    constructCustomerIdentificationModel(orderLine): IdentificationCustomer {
        return {
          CalculatedName: orderLine.OriginalCustomerName,
          CustomerType: orderLine.OriginalCustomerType,
          CustomerName: orderLine.OriginalCustomerName,
          IsIdentified: orderLine.OriginalCustomerIsIdentified,
          CustomerNumber: orderLine.OriginalCustomerNumber,
          TicketCustomerId: orderLine.OriginalCustomerId,
        }
    }
}
