<t4-list-view #listVw [options]="listOptions" (onDataRequest)="doPage($event)">
    <ng-container *t4ListItem="let item">
        <td *ngIf="item.PassePartoutName == null">{{item.EventDate | localDateTime }} - {{item.EventName}}</td>
        <td *ngIf="item.PassePartoutName != null">{{ item.PassePartoutName | replaceOnEmpty:''}}</td>
        <td>{{item.ParentSectionName}} / {{item.RowNumber | replaceOnEmpty}} / {{item.SeatNumber | replaceOnEmpty}}</td>
        <td>{{item.TicketCustomer}}</td>
        <td>{{ item.Name }}</td>
        <td>{{ item.StartSeasonName }}</td>
        <td>
          <span *ngIf="item.DiscountAbsolute">{{ item.DiscountAbsolute | currency:'EUR' }}</span>
          <span *ngIf="item.DiscountRelative">{{ item.DiscountRelative }}%</span>
        </td>
        <td>{{item.Total | currency:'EUR'}}</td>
    </ng-container>
</t4-list-view>