import { ModuleWithProviders, NgModule } from "@angular/core";
import { NotificationCommunicationService } from "../services/notification.communication.service";



@NgModule({})
export class NotificationModule {
  static forRoot(): ModuleWithProviders<NotificationModule> {
    return {
      ngModule: NotificationModule,
      providers: [
        { provide: NotificationCommunicationService, useClass: NotificationCommunicationService }
      ]
    };
  }
}