import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {LoggingService, SessionService} from '@tymes4-shared';
import {Observable, throwError as observableThrowError} from 'rxjs';

import {catchError, map} from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router, private logging: LoggingService, private sessionService: SessionService) {
  }

  storeState(resp: any) {
    var stateHeader = resp['headers'].get('state');

    if (stateHeader !== null) {
      sessionStorage.setItem('state', stateHeader);
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const loginPageUrl = 'error/invalid-session';

    // //check if the session is already known, if so pass it to the server
    if (sessionStorage.getItem('state') != null) {
      req = req.clone({headers: req.headers.set('State', sessionStorage.getItem('state'))});  
    }
    
    if (req.headers.get('no-auth') == 'true') {
      
      return next.handle(req).pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.storeState(event);
          }
          return event;
      }));

    }

    // check if the user has been authenticated
    if (this.sessionService.isLoggedIn()) {
      // pass our token in the header
      const authHeader = 'Bearer ' + sessionStorage.getItem('jwt');
      const channel = sessionStorage.getItem('userSelectedChannel');

      // The channel the user selected will always need to be passed.
      let authHeaders = req.headers.set('Authorization', authHeader);

      if (channel !== null) {
        authHeaders = authHeaders.set('Channel', channel);
      }

      const authReq = req.clone({headers: authHeaders});
      
      return next.handle(authReq).pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.storeState(event);
          }
          return event;
      }),
      ).pipe(
        catchError((error, caught) => {

          // intercept the respons error
          if (error.status == 401) {
            this.sessionService.logOff();
            this.router.navigateByUrl(loginPageUrl);
          }

          return observableThrowError(error);

        })) as any;
    } else {
      // no token present in local store
      this.router.navigate([loginPageUrl]);
    }
  }
}
 