import {Injectable} from '@angular/core';
import {LoggingService} from './logging.service';
import {BehaviorSubject, Subject} from 'rxjs';
import {Language} from '../models/entities/language';
import {BaseUser} from '../models/session/base-user';


@Injectable({providedIn: 'root'})
export class SessionService {

  public $user = new BehaviorSubject<BaseUser>(undefined);
  public $token = new BehaviorSubject<string>(undefined);
  public $language = new BehaviorSubject<Language>(undefined);
  public $activeSalesChannelId = new BehaviorSubject<number>(undefined);
  public $isArchiveMode = new BehaviorSubject<boolean>(undefined);
  public $pastAndCurrentSeasonDates = new BehaviorSubject<string>(undefined);
  public $hasArchiveDB = new BehaviorSubject<boolean>(undefined);
  public $logOff = new Subject<void>();


  constructor(private loggingService: LoggingService) {
  }

  public setToken(token: string) {
    if(token) {
      sessionStorage.setItem('jwt', token);
      this.$token.next(token);
    }
  }

  public getToken(): string {
    return sessionStorage.getItem('jwt');
  }

  public setUser(user: BaseUser): void {
    if(!user) {
      this.loggingService.warn('User is null');
      return;
    }

    sessionStorage.setItem('userData', JSON.stringify(user));
    var currentChannel = sessionStorage.getItem('userSelectedChannel');
    if (currentChannel != null)
    {
      this.setActiveSalesChannel(parseInt(currentChannel));
    } else {
      // the user is fetched, set the default channel
      const channels = user.Channels;

      if (channels && channels.length > 0) {
        // get the default channel
        const defaultChannels = channels.filter((c) => c.IsPrimary === true);

        if (defaultChannels.length > 0) {
          this.loggingService.log(`User default channel: ${defaultChannels[0].Name} (${defaultChannels[0].SalesChannelId})`);
          this.setActiveSalesChannel(defaultChannels[0].SalesChannelId);
        } else {
          this.loggingService.error('No default channel found for user');
          this.setActiveSalesChannel(null);
        }
      } else {
        this.loggingService.error('No channels found for user');
          this.setActiveSalesChannel(null);
      }
    }

    var currentArchiveMode = sessionStorage.getItem('userIsArchiveMode');
    if(currentArchiveMode != null && currentArchiveMode != undefined){
      this.setArchiveMode(currentArchiveMode == "true");
    }
    else{
      const archiveMode = user.IsArchiveMode;
      this.setArchiveMode(archiveMode)
    }

    var pastAndCurrentSeasonDates = sessionStorage.getItem('pastAndCurrentSeasonDates');
    if(pastAndCurrentSeasonDates != null){
      this.setPastAndCurrentSeasonDates(pastAndCurrentSeasonDates);
    }
    var hasArchiveDB = sessionStorage.getItem('hasArchiveDB');
    if(hasArchiveDB != null){
      this.setHasArchiveDB(hasArchiveDB == "true");
    }
    this.$user.next(user);
  }



  public isLoggedIn(): boolean {
    let result = !!this.getToken();
    return result;
  }

  public setActiveSalesChannel(salesChannelId: number) {
    sessionStorage.setItem('userSelectedChannel', `${salesChannelId}`);

    if(this.$activeSalesChannelId.value !== salesChannelId){
      this.$activeSalesChannelId.next(salesChannelId);
    }
  }

  public setArchiveMode(newMode: boolean) {
    const isArchiveMode = this.$isArchiveMode.getValue();

    if(isArchiveMode !== newMode){
      this.$isArchiveMode.next(newMode);
    }
  }

  public setPastAndCurrentSeasonDates(data) {
    if(data != null){
      if(this.$pastAndCurrentSeasonDates.value !== data){
        this.$pastAndCurrentSeasonDates.next(data);
      }
    }
  }

  public setHasArchiveDB(data) {
    if(data != null){
      if(this.$hasArchiveDB.value !== data){
        this.$hasArchiveDB.next(data);
      }
    }
    else{
      this.$hasArchiveDB.next(false);
    }
  }

  public getLanguage(): Language {
    var json = sessionStorage.getItem('user_language');

    if (json != null)
      return JSON.parse(json);

    return null;
  }

  public setLanguage(language: Language) {
    sessionStorage.setItem('user_language', JSON.stringify(language));
    this.$language.next(language);
  }

  public logOff() {
    sessionStorage.removeItem('endpoint');
    sessionStorage.removeItem('userIsArchiveMode');
    sessionStorage.removeItem('hasArchiveDB');
    sessionStorage.removeItem('pastAndCurrentSeasonDates');
    sessionStorage.removeItem('state');
    sessionStorage.removeItem('ticketShopURL');
    sessionStorage.removeItem('userSelectedChannelCode');
    sessionStorage.removeItem('user_language');
    sessionStorage.removeItem('userToken');
    sessionStorage.removeItem('userSelectedChannel');
    sessionStorage.removeItem('userData');
    sessionStorage.removeItem('user_language');
    sessionStorage.removeItem('jwt');
    sessionStorage.removeItem('active_modules');

    this.$isArchiveMode.next(undefined);
    this.$logOff.next();
  }

}
