import { Observable } from 'rxjs';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import {ConfirmComponent} from '../components/confirm/confirm.component';
import {ConfirmData} from '../models/common/confirm-data';
import { TranslateService } from '@ngx-translate/core';

@Injectable({providedIn: 'root'})
export class ConfirmService {

  constructor(private dialog: MatDialog, private translate: TranslateService) { }

  public confirm(data: ConfirmData = {}, width: any = '380px'): any { //tymes4v2: hack to make it work again

    data.title = data.title || 'GENERIC.CONFIRM.CONFIRM';
    data.message = data.message || 'GENERIC.CONFIRM.WARNING.TITLE.ARE-YOU-SURE';

    data.title = this.translate.instant(data.title, data.titleParams);
    data.message = this.translate.instant(data.message, data.messageParams);

    let dialogRef: MatDialogRef<ConfirmComponent>;

    dialogRef = this.dialog.open(ConfirmComponent, {
      width: width,
      disableClose: true,
      panelClass: 'confirmBox',
      data
    });

    return dialogRef.afterClosed();
  }

  public okOnlyConfirm(title: string, message: string): Observable<boolean> {
    return this.confirm({
      title: title,
      message: message,
      okonly: true
    });
  }

  public yesNoCancelConfirm(title: string, message: string): Observable<boolean> {
    return this.confirm({
      title: title,
      message: message
    });
  }

  public okAndCancelConfirm(title: string, message: string): Observable<boolean> {
    return this.confirm({
      title: title,
      message: message
    });
  }
}
