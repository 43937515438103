import {HttpClient} from '@angular/common/http';
import {TranslateLoader} from '@ngx-translate/core';
import {Observable} from 'rxjs';

export class TranslateHttpLoader implements TranslateLoader {
  constructor(private http: HttpClient) {}

  /**
   * Gets the translations from the server
   */
  public getTranslation(lang: string): Observable<any> {
    var apiUrl = sessionStorage.getItem('endpoint');
    
    return this.http.get(`${apiUrl}/V2/language/translations/${lang}`, {headers: { 'no-auth': 'true' }});
  }
}
