
import * as moment from 'moment'; 
import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import {isValidIBAN} from 'ibantools';

// @dynamic
@Injectable()
export class T4Validators {
  constructor() {}
  
  static validateIban(c: AbstractControl): ValidationErrors | null {
    
    if (c.value != null)
    {
      const iban = c.value.toUpperCase().replace(/ /g, '');
      if (isValidIBAN(iban)) {
        if (iban !== c.value) {
          c.patchValue(iban);
        }
        return null;
      }
    }

    return {
      validateIban: {
        valid: false
      }
    };
  }
 
  
  static validatePhonenumber = (control: AbstractControl) => {

    if (control.value === null || control.value === ''){
        return null;
    }

    let phone = control.value;
    var regex = new RegExp(sessionStorage.getItem('phoneValidation'));

    // var landLine = /^(((0)[1-9]{2}[0-9][-]?[1-9][0-9]{5})|((\\+31|0|0031)[1-9][0-9][-]?[1-9][0-9]{6}))$/;
    // var mobile = /^(((\\+31|0|0031)6){1}[1-9]{1}[0-9]{7})$/i;
    var valid = regex.test(phone);

    if (valid === false){
        return {'phonenumber': true};
    }
    else{
        control.setErrors(null);
        return null;
    }
}
}

