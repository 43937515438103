import {Component, ComponentFactoryResolver, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {
  BaseUser,
  EnvironmentService, InjectComponentService,
  KeyValuePair,
  RoutePartsService,
  SessionService,
  VersionCheckService
} from '@tymes4-shared';
import {AuthService} from '../app/shared//services/auth/auth.service';
import { MappingHelper } from '../../../Backoffice/src/app/shared/helpers/mapping-helper';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import { FanManagementUser, Language, LanguageService, UserService } from './shared/api';
import {NavHeaderComponent} from './shared/components/nav-header/nav-header.component';

// import {LanguageService} from './shared/services/http/language.service';

@Component({
  selector: 'fan-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

  public logOffSubscription: Subscription;
  public languageSubscription: Subscription;
  public activeSalesChannelSubscription: Subscription;

  public appTitle = 'Tymes4 Fanmanagement';

  constructor(
    public title: Title,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private routePartsService: RoutePartsService,
    private versionCheckService: VersionCheckService,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private sessionService: SessionService,
    private environmentService: EnvironmentService,
    private authService: AuthService,
    private injectComponentService: InjectComponentService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private userService: UserService
  ) {
  }

  ngOnInit() {
    // Translations
    this.languageService.getActiveLanguage(false).subscribe((language: Language) => {
      this.sessionService.setLanguage(MappingHelper.MapAPILanguageToSessionLanguage(language));
    });

    const navHeaderFactory = this.componentFactoryResolver.resolveComponentFactory(NavHeaderComponent);
    this.injectComponentService.injectComponent('navHeaderInjection', navHeaderFactory);

    // Handle page title updates
    this.changePageTitle();

    //TODO: Implement version service
    // const env = this.environmentService.loadConfigurations();
    // // Initialize the version check service
    // const checkInterval = env.versionCheckInterval;

    // if (checkInterval > 0) {
    //   console.log('Running version checked.');
    //   this.versionCheckService.initVersionCheck(env.versionCheckURL, checkInterval * 1000);
    // } else {
    //   console.log('Update checking has been disabled.');
    // }

    // Reload user on startup
    if (this.sessionService.isLoggedIn()) {
      this.userService.getCurrentFanUser().subscribe((user: FanManagementUser) => {
        this.authService.setUser(user);
      }, (error) => this.sessionService.logOff());
    }

    /**
     * Event handlers
     */
    this.logOffSubscription = this.sessionService.$logOff.subscribe(() => {
      this.router.navigateByUrl('/');
    });

    this.languageSubscription = this.sessionService.$language.subscribe( (language: Language) => {
      if(language) {
        this.languageService.getTranslationsByLanguage(language.Code, false).subscribe((langDict: { [key: string]: string; }) => {
          this.translateService.setTranslation(language.Code, langDict, false);
          this.translateService.use(language.Code).subscribe(() => {});
        });
      }
    });
    this.activeSalesChannelSubscription = this.sessionService.$activeSalesChannelId.subscribe(() => {

    });
  }

  ngOnDestroy(): void {
    this.logOffSubscription.unsubscribe();
    this.languageSubscription.unsubscribe();
    this.activeSalesChannelSubscription.unsubscribe();
  }

  changePageTitle() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routeChange) => {
      let routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
      if (!routeParts.length) {
        return this.title.setTitle(this.appTitle);
      }
      else
      {
        this.translateService.stream(routeParts[0].title).subscribe(title => {
          this.title.setTitle(`${title} | ${this.appTitle}`);  
        })
      }
    });
  }
}
