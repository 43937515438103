import { ListViewComponent } from './../../list-view/list-view.component';
import { PagedResult } from './../../../models/list-view/pagedResult';
import { ListViewSelectionMode } from './../../../enums/list-view-selection-mode.enum';
import { Component, Input, OnInit, ViewChild, AfterViewInit, Output, EventEmitter, SimpleChanges } from "@angular/core";

@Component({
    selector: 't4-discount-lines',
    templateUrl: './discount-lines.component.html'
})
export class DiscountLinesComponent implements OnInit, AfterViewInit {

    @Input() public discountLines: Array<any>;
    @Input() public isInEditMode: boolean = false;
    @Output() public onRemoveDiscountCode = new EventEmitter<number>();

    @ViewChild(ListViewComponent) listVw : ListViewComponent;

    public listOptions = {
        search: false,
        showBarcodeSearch: false,
        pageSize: 7 ,
        noItemsText: 'T4.ORDERDETAILS.DISCOUNTLINES.NODISCOUNT',
        selectionMode: ListViewSelectionMode.None,
        disableSort: true,
        noSpacing: true,
        renderInCard: false,
        headers: [] // assigned in updateHeaders
    };

    public discountLinesLength;
    public pagedDiscountLines;

    constructor() { }

    doPage($event) {
        this.pagedDiscountLines = this.discountLines.slice(($event.pageNr - 1) * $event.pageSize, ($event.pageNr) * $event.pageSize);
        
        let d : PagedResult = {
            TotalRecords: this.discountLinesLength,
            Records: this.pagedDiscountLines
        };

        this.listVw.Data = d;
    }

    ngOnInit(): void {
        this.discountLinesLength = this.discountLines.length;

        this.updateHeaders();
    }

    ngOnChanges(value: SimpleChanges) {
        let isInEditMode = value?.isInEditMode
        if (isInEditMode && isInEditMode.currentValue != isInEditMode.previousValue) {
            this.updateHeaders();
        }
    }

    updateHeaders() {
        let headers = [
            {sortField: null, label: 'T4.ORDERDETAILS.HEADER.DISCOUNT' },
            {sortField: null, label: 'T4.ORDERDETAILS.HEADER.AMOUNT', width: 80, class: 'text-right'}
        ];
        if (this.isInEditMode){
            headers.push({sortField: null, label: ''});
        }
        this.listOptions.headers = headers;
    }

    ngAfterViewInit() {
    
    }

    removeDiscountCode(item) {
        this.onRemoveDiscountCode.emit(item.DiscountCodeId);
    }
}
