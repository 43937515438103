import { Injectable } from '@angular/core';
import {ApiDetails} from '../models/http/api-details';
import {EnvironmentService} from './environment.service';

@Injectable()
export class LoggingService {

  public environment: ApiDetails;
  constructor(private environmentService: EnvironmentService) {
    this.environment = environmentService.loadConfigurations();
  }

  log(data) {
    if (!this.environment || !this.environment.production) {
      console.log(data);
    }
  }

  warn(data) {
    if (!this.environment || !this.environment.production) {
      console.warn(data);
    }
  }

  error(data) {
    if (!this.environment || !this.environment.production) {
      console.error(data);
    }
  }

  groupStart(title ?: string) {
    if (!this.environment || !this.environment.production) {
      console.groupCollapsed(title);
    }
  }

  groupEnd() {
    if (!this.environment || !this.environment.production) {
      console.groupEnd();
    }
  }
}
