<t4-list-view #listVw [options]="listOptions" (onDataRequest)="doPage($event)">
    <ng-container *t4ListItem="let item">
        <ng-container [ngSwitch]="item.ProductType">

            <ng-container *ngSwitchCase="ProductTypeEnum.Ticket">
              <td>{{item.EventDate | localDateTime }} - {{item.EventName}}</td>
              <td>{{item.ParentSectionName}} / {{item.RowNumber | replaceOnEmpty}}
                / {{item.SeatNumber | replaceOnEmpty}}</td>
              <td><t4-customer-identification-control [isIbaActive]="true" [showCustomerNumber]="true" [customer]="constructCustomerIdentificationModel(item)"></t4-customer-identification-control></td>
              <td><t4-customer-identification-control [isIbaActive]="true" [showCustomerNumber]="false" [customer]="constructOriginalCustomerIdentificationModel(item)"></t4-customer-identification-control></td>
              <td><a (click)="routeToOrder(item.OriginalOrderId)" class="clickable-customer-name">{{item.OriginalOrderId}}</a></td>
              <td>{{item.OriginalOrderDate | localDateTime }}</td>
              <td>{{item.Total | currency:'EUR'}}</td>
            </ng-container>

            <ng-container *ngSwitchCase="ProductTypeEnum.DeliveryMethod" >
              <td>{{'T4.ORDERDETAILS.ORDERLINES.DELIVERY-COST' | translate}}</td>
              <td colspan="5"></td>
              <td>{{item.Total | currency:'EUR'}}</td>
            </ng-container>

            <ng-container *ngSwitchCase="ProductTypeEnum.PaymentMethod">
              <td>{{'T4.ORDERDETAILS.ORDERLINES.MARKUP-PAYMENTMETHOD' | translate}}</td>
              <td colspan="5"></td>
              <td>{{item.Total | currency:'EUR'}}</td>
            </ng-container> 
          
            <ng-container *ngSwitchDefault>
              <td colspan="11">
                <span class="fas fa-exclamation-triangle"></span>
                <span class="mx-2">{{'T4.ORDERDETAILS.ORDERLINES.CANNOTSHOWLINE' | translate}}</span>
                <span class="fas fa-exclamation-triangle"></span>
              </td>
            </ng-container>

          </ng-container>

    </ng-container>
</t4-list-view> 