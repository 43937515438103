import * as moment from 'moment-timezone';

import {Component, EventEmitter, Input, Output} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SessionService } from '../../services/session.service';
import { DateEditorType } from '../../enums/date-editor-type';

@Component({
  selector: 't4-date-time-control',
  templateUrl: 'date-time-control.component.html', 
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi:true,
    useExisting: DateTimeControlComponent
  }]
})
export class DateTimeControlComponent implements ControlValueAccessor {
  
  public valueUTC: moment.Moment; //date in utc.
  public valueInTimeZone: moment.Moment;
  public dateText:string = ''; //what the datepicker displays
  public timeText:string = ''; //what the timepicker displays

  onChange = (dte: string) => {};
  onTouched = () => {};
  touched = false;
  disabled = false;

  private timeZone = 'UTC';
  private dateFormat = '';
  private timeFormat = '';
  public colWidth: number = 6;

  //hack for the html
  DateEditorType = DateEditorType;

  @Input() beforeToday: boolean = false;
  @Input() required: boolean = true;
  @Input() labelDate: string = ''; 
  @Input() labelTime: string = ''; 
  @Input() type: DateEditorType = DateEditorType.DateTime;
  @Input() datePlaceholder: string = '';
  @Input() timePlaceholder: string = '';

  @Input()
  set editorType(et: DateEditorType) {
    this.type = et;
    if (et == DateEditorType.DateTime) this.colWidth = 6;
    else this.colWidth = 12;
  }

  @Input() timezone: string = 'UTC'; // default timezone is UTC

  @Output() changeEvent = new EventEmitter<string>();

  public formControl: FormControl = new FormControl();

  constructor(private sessionService: SessionService) {

    var language = this.sessionService.getLanguage();

    if (language == null) 
      console.error("No language set, unable to determine timezone and formatting information.");
      
    this.timeZone = language.TimeZone;
    this.dateFormat = language.FormatDate;
    this.timeFormat = language.FormatTime;
  }

  writeValue(dte: string) {

    if (dte !== null && dte !== undefined && dte !== '') {
      //we store the value in UTC, but display it in the timezone of the user.
      this.setDatesFromString(dte);
    }
    
  }
 
  setDatesFromString(dte: string) {

    this.valueUTC = moment.utc(dte);
    this.valueInTimeZone =  moment.utc(dte)
    this.valueInTimeZone = this.valueInTimeZone.tz(this.timeZone);

    this.dateText = this.valueInTimeZone.format(this.dateFormat);
    this.timeText = this.valueInTimeZone.format(this.timeFormat);
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
    
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onDateChange(e) {
    
    if (this.dateText == null || this.timeText == null || this.timeText == '')
    {
      this.valueUTC = null;
    }
    else {
      //setup the date and time in the timezone of the user.
      const [hours, minutes] = this.timeText.split(':');
      this.valueUTC = moment.tz(this.dateText, this.dateFormat, this.timeZone);
      
      this.valueUTC.set({
        hour: parseInt(hours),
        minute: parseInt(minutes),
      });

      //convert to the utc zone.
      this.valueUTC = this.valueUTC.tz('UTC');
    }
  
    this.markAsTouched();

    let val:string = this.valueUTC !== null ? this.valueUTC.toISOString() : null; // don't set keepOffset to true; backend would then interpret it as a local time even though it's UTC
    this.onChange(val);
    this.changeEvent.emit(val);
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  // private getTimezoneOffset(timezone: string): number {
  //   const date = new Date();
  //   const offset = date.toLocaleString('en-US', { timeZone: timezone })
  //     .match(/([-\+][0-9]+)\s/)[1];
  //   return Number(offset) * 60 * 1000; // offset in milliseconds
  // }
}