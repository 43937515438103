

    <div class="mg-t-20 ">
        <div class="pagination-wrapper" *ngIf="totalObjectCount > 0">
    

            <ul class="pagination mg-b-0" *ngIf="pager.pages && pager.pages.length > 1">
                <li class="clickable page-item d-none d-sm-block" class.disabled="pager.currentPage === 1" *ngIf="currentPageNr != 1">
                    <a class="page-link"(click)="doPage(1)" aria-label="First"><i class="fa fa-angle-double-left"></i></a>
                </li>
        
                <li class="clickable page-item"  [class.active]="pager.currentPage === page" *ngFor="let page of pager.pages; let idx = index">
                    <a class="page-link" (click)="doPage(page)">{{page}}</a>
                </li>
        
                <li class="clickable page-item d-none d-sm-block"  *ngIf="pager.currentPage != pager.totalPages">
                    <a class="page-link" (click)="doPage(pager.totalPages)"><i class="fa fa-angle-double-right"></i></a>
                </li>
        
            </ul>
        </div>

        <div *ngIf="listCounts && totalObjectCount > pageSize" class="pagination pagination-text">
            {{ getPaginationText() }} 
        </div>

        <span *ngIf="resultsCapped === true" class="pagination-text">
            {{ 'LISTVIEW.RESULTSCAPPED' | translate }}
        </span>

    </div>

  