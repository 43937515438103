<div class="row modal-bar">
    <div class="col-sm-6">
        <h5 class="modal-title">{{ passedData.title | translate }}</h5>
    </div>
    <div class="col-sm-6">
        <div class="modal-actions">
            <div class="modal-close">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span (click)="dialogRef.close(false);">×</span>
                </button>
            </div>
        </div>
    </div>
  </div>
  
  
  <div class="form-group mt-5" style="max-height:400px; overflow: auto; ">
    <t4-stock-mutations *ngIf="stockMutations != null" [stockMutations]="stockMutations"></t4-stock-mutations>
  </div>

