import {HttpClientModule} from '@angular/common/http';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {EnvironmentService} from '../services/environment.service';

export function loadEnvironment(envService: EnvironmentService) {
  const val = () => {
    return envService.init().toPromise();
  };

  return val;
}

@NgModule({
  declarations: [],
  imports: [
    HttpClientModule
  ],
  exports: [],
  providers: [
    EnvironmentService,
    {
      provide: APP_INITIALIZER,
      useFactory: loadEnvironment,
      deps: [EnvironmentService],
      multi: true
    }
  ]
})
export class EnvironmentModule { }
