import { TranslateService } from '@ngx-translate/core';
import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig, MatSnackBarRef, SimpleSnackBar} from '@angular/material/snack-bar';
import {EnvironmentService} from './environment.service';

@Injectable()
export class SnackbarService {

  constructor(private snackBar: MatSnackBar, private environmentService: EnvironmentService, private translateService: TranslateService) {
  }

  public error(message: string): MatSnackBarRef<SimpleSnackBar> {

    message = this.translateService.instant(message);
    
    let config : MatSnackBarConfig = {
      panelClass: 'mat-error'
    };
    
    this.translateService.get(message).subscribe(res => {
      message = res;
    })
    
    config.duration = 12 * 60 * 60 * 1000; //12 hours, more or less forever
    return this.snackBar.open(message,  null, config);
  }

  public open(message: string, action?: string, config?: MatSnackBarConfig): MatSnackBarRef<SimpleSnackBar> {

    message = this.translateService.instant(message);
    if (action) action = this.translateService.instant(action);

    if (!config) {
      config = {};
    }

    this.translateService.get(message).subscribe(res => {
      message = res;
    })
    
    config.duration = 2500;
    return this.snackBar.open(message, action, config);
  }
}
 