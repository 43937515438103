import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {catchError} from 'rxjs/operators';
import {DataService, EnvironmentService} from '@tymes4-shared';
import {LoggingService} from '@tymes4-shared';

@Injectable()
export class StatisticsServiceOld extends DataService {

  constructor(private httpClient: HttpClient, logging: LoggingService, environmentService: EnvironmentService) {
    super(httpClient, environmentService);
    this.entityName = 'statistics';
  }

  getDashboardStatistics(statType: string, interval: string, forceRefresh: boolean): any {
    var url = this.getUrl() + `/dashboard/${statType}/${interval}/${forceRefresh}`;
    return this.httpClient.get(url).pipe(catchError(this.handleError));
  }
}