<aside class="left-sidebar sidebar-dark" id="left-sidebar"
  style="background-image:url('/assets/img/sidebar/sidebar-bg-05.jpg'); background-size: cover; background-position: center;">
  <div id="sidebar" class="sidebar sidebar-with-footer">
    <!-- Application Brand -->
    <div class="app-brand">
      <div class="app-brand-archief" *ngIf="isInArchiveMode">
        <i class="fas fa-history"></i><span>{{ 'T4.PAGE-HEADER.ARCHIVE' | translate }}</span>
      </div>
      <ng-template *ngIf="!isInArchiveMode" t4Inject></ng-template>
    </div>

    <!-- begin sidebar scrollbar -->
    <div class="sidebar-scrollbar" id="sidebar-scrollbar">

      <!-- sidebar menu -->

      <ul class="nav sidebar-inner" id="sidebar-menu">

        <li (click)="setExpanded(item.cleanState)"
          [class.expand]="item.cleanState === expandedMnuName && this.expandedMnuState"
          [class.has-sub]="item.SubItems && item.SubItems.length > 0" [class.section-title]="item.Type==='subHeader'"
          routerLinkActive="active" *ngFor="let item of menuItems">

          <ng-container [ngSwitch]="item.Type">

            <a *ngSwitchCase="'link'" class="sidenav-item-link" routerLink="/{{item.State}}">
              <i class="{{item.Icon}}"></i>
              <span>{{item.Name | translate}}</span>
            </a>

            <span *ngSwitchCase="'subHeader'" >{{item.Name | translate}}</span>

            <a *ngSwitchDefault class="sidenav-item-link clickable" role="button" data-toggle="collapse"
               [attr.aria-expanded]="item.cleanState === expandedMnuName && this.expandedMnuState ? 'true' : 'false'"
              >
              <i class="{{item.Icon}}"></i>
              <span class="nav-text">{{item.Name | translate}}</span>
              <b *ngIf="item.SubItems && item.SubItems.length > 0" class="caret"></b>
            </a>


          </ng-container>


          <ng-container *ngIf="item.SubItems && item.SubItems.length > 0">

            <ul class="collapser" [class.showed]="item.cleanState === expandedMnuName && this.expandedMnuState" data-parent="#sidebar-menu">
              <div class="sub-menu">

                <ng-container *ngFor="let subItem of item.SubItems">

                  <ng-container *ngIf="subItem.Type ==='subHeader'; else subItemLink">
                    <li class="section-title">
                      <div class="section-title-text">{{subItem.Name | translate}}</div>
                    </li>

                    <li *ngFor="let subSubItem of subItem.SubItems"
                        routerLinkActive="active"
                        [class.has-sub]="subSubItem.SubItems && subSubItem.SubItems.length > 0">

                      <a class="sidenav-item-link"
                        routerLink="{{item.State ? '/'+item.State : ''}}/{{subItem.State ? '/'+subItem.State : ''}}/{{subSubItem.State}}">
                        <span class="nav-text">{{subSubItem.Name | translate}}</span>
                      </a>
                    </li>

                  </ng-container>

                  <ng-template #subItemLink>
                    <li routerLinkActive="active">
                      <a class="sidenav-item-link" routerLink="{{item.State ? '/'+item.State : ''}}/{{subItem.State}}">
                        <span class="nav-text">{{subItem.Name | translate}}</span>
                      </a>
                    </li>
                  </ng-template>

                </ng-container>




              </div>
            </ul>

          </ng-container>

        </li>

      </ul>

    </div>
    <!-- <div class="sidebar-footer">
      <div class="sidebar-footer-content">
        <ul class="d-flex">
          <li>
            <a href="user-account-settings.html" data-toggle="tooltip" title="Profile settings"><i
                class="mdi mdi-settings"></i></a></li>
          <li>
            <a href="#" data-toggle="tooltip" title="No chat messages"><i class="mdi mdi-chat-processing"></i></a>
          </li>
        </ul>
      </div>
    </div> -->
  </div>
</aside>
