import { ListViewComponent } from './../../list-view/list-view.component';
import { PagedResult } from '../../../models/list-view/pagedResult';
import { ListViewSelectionMode } from './../../../enums/list-view-selection-mode.enum';
import { Component, Input, OnInit, ViewChild, AfterViewInit } from "@angular/core";

@Component({
    selector: 't4-subscription-lines',
    templateUrl: './subscription-lines.component.html'
})
export class SubscriptionLinesComponent implements OnInit, AfterViewInit {

    @Input() public orderDetails: any;

    @ViewChild(ListViewComponent) listVw : ListViewComponent;

    public listOptions = {
        search: false,
        showBarcodeSearch: false,
        pageSize: 7 ,
        noItemsText: 'T4.ORDERDETAILS.SUBSCRIPTIONLINES.NOSUBSCRIPTIONS',
        selectionMode: ListViewSelectionMode.None,
        disableSort: true,
        renderInCard: false,
        noSpacing: true,
        headers: [
           { sortField: null, label: 'T4.ORDERDETAILS.HEADER.EVENTPASSEPARTOUT', hideOn: null },
           { sortField: null, label: 'T4.ORDERDETAILS.HEADER.SECTIONROWSEAT', hideOn: null, width: 150 },
           { sortField: null, label: 'T4.ORDERDETAILS.HEADER.INTHENAMEOF', hideOn: null, width: 190 },
           { sortField: null, label: 'T4.ORDERDETAILS.HEADER.SUBSCRIPTION', hideOn: null },
           { sortField: null, label: 'T4.ORDERDETAILS.HEADER.STARTS', hideOn: null },
           { sortField: null, label: 'T4.ORDERDETAILS.HEADER.DISCOUNT', hideOn: null, class: 'text-right' },
           { sortField: null, label: 'T4.ORDERDETAILS.HEADER.TOTAL', hideOn: null, class: 'text-right' },
        ]
      };

    public subscriptionLines;
    public subscriptionLinesLength;
    public pagedSubscriptionLines;

    constructor() { }

    doPage($event) {
        this.pagedSubscriptionLines = this.subscriptionLines == null ? [] : this.subscriptionLines.slice(($event.pageNr - 1) * $event.pageSize, ($event.pageNr) * $event.pageSize);

        let d : PagedResult = {
          TotalRecords: this.subscriptionLinesLength,
          Records: this.pagedSubscriptionLines
        };

        this.listVw.Data = d;
    }

    ngOnInit(): void {
        this.orderDetails = this.addPropertiesToSubscriptionLines(this.orderDetails);
        this.subscriptionLines = this.orderDetails.SubscriptionViews;
        this.subscriptionLinesLength = this.subscriptionLines == null ? 0 : this.subscriptionLines.length;
    }

    ngAfterViewInit() {
    
    }

    addPropertiesToSubscriptionLines(order: any) {
        if (order.SubscriptionViews == null) return order;

        for (const subscription of order.SubscriptionViews) {
          // console.log(subscription)
          const orderLine = order.OrderLines.filter(ol => ol.Id === subscription.OrderLineId)[0];
          if (orderLine) {
            subscription.EventDate = orderLine.EventDate;
            subscription.ParentSectionName = orderLine.ParentSectionName;
            subscription.TicketCustomer = orderLine.TicketCustomer;
            subscription.PassePartoutName = orderLine.PassePartoutName;
            subscription.RowNumber = orderLine.RowNumber;
            subscription.SeatNumber = orderLine.SeatNumber;
            subscription.Total = orderLine.Total;
          }
        }
        return order;
      }

}
