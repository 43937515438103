import * as moment from 'moment-timezone';
import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { SessionService } from '../services/session.service';


@Pipe({ name: 'relativeTime' })
export class RelativeTimePipe implements PipeTransform {

  constructor(private translate: TranslateService, private sessionService: SessionService) {
  }

  transform(value: string) {

    if (value == null || value === undefined || value === '') return '-';

    var language = this.sessionService.getLanguage();

    if (language == null)
      console.error('localDateTimePipe cannot be used when language == null.');

    var valueMoment = moment.utc(value);
    let seconds: number = Math.floor(((new Date()).getTime() - valueMoment.tz(language.TimeZone).toDate().getTime()) / 1000);
    let interval: number = Math.floor(seconds / 31536000);

    if (interval > 1) {
      return this.translate.instant('RELATIVE-TIME.PIPE.YEAR.AGO', { interval: interval });
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
      return this.translate.instant('RELATIVE-TIME.PIPE.MONTHS.AGO', { interval: interval });
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
      return this.translate.instant('RELATIVE-TIME.PIPE.DAYS.AGO', { interval: interval });
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
      return this.translate.instant('RELATIVE-TIME.PIPE.HOURS.AGO', { interval: interval });
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
      return this.translate.instant('RELATIVE-TIME.PIPE.MINUTES.AGO', { interval: interval });
    }
    return this.translate.instant('RELATIVE-TIME.PIPE.SECONDS.AGO', { interval: Math.floor(seconds) });
  }
}