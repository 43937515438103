import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {PagerService} from '../../services/pager.service';

@Component({
  selector: 't4-pager-irregular-size',
  templateUrl: './pager-irregular-size.component.html',
  styleUrls: ['./pager-irregular-size.component.scss']
})
export class PagerIrregularSizeComponent implements OnInit {

  constructor(private pagerService: PagerService) { }

  @Output() onPage = new EventEmitter<object>();

  public pageCount: number;
  public pager: any = {};
  public currentPageNr = 1;

  ngOnInit() {
    this.currentPageNr = 1;
  }

  doPage (pageNr) {
    this.currentPageNr = pageNr;

    this.onPage.emit( {
      pageNr
    });
  }

  public setPageCount(count: number) {
    this.pageCount = count;
    this.setPage(this.currentPageNr);
  }

  public setPage(page: number) {
    // get pager object from service
    this.doPage(page);
    this.pager = this.pagerService.getPagerIrregular(this.pageCount, page);
  }
}
