<header class="main-header" id="header">


  <nav class="navbar navbar-expand-lg navbar-light" [style]="getBackgroundStyle()" id="navbar">

    <!-- Sidebar toggle button -->
    <button id="sidebar-toggler" class="sidebar-toggle" (click)="toggleNavigation()">
      <span class="sr-only">Toggle navigation</span>
    </button>

    <span class="page-title">{{pageTitle | translate}}</span>

    <div class="navbar-right ">

      <ul class="nav navbar-nav">

        <!-- Data Archiving dropdown-->

        <li *ngIf="hasArchiveDB" class="dropdown data-menu">
          <button href="#" class="dropdown-toggle nav-link" data-toggle="dropdown" [ngClass]="isArchiveMode ? 'dropdown-archive' : 'dropdown-live' ">
            <span *ngIf="isArchiveMode" class="d-none d-lg-inline-block">{{ 'T4.PAGE-HEADER.ARCHIVEUNTIL' | translate }} {{archiveDates[1]}})</span>
            <span *ngIf="!isArchiveMode" class="d-none d-lg-inline-block">{{ 'T4.PAGE-HEADER.LIVE' | translate }}</span>
          </button>
          <ul class="dropdown-menu dropdown-menu-right clickable">

            <li class="dropdown-menu__item clickable">
              <a class="dropdown-link-item" (click)="setArchiveMode(false)">
                <span *ngIf="showArchiveDates()" class="nav-text">{{ 'T4.PAGE-HEADER.LIVEFROM' | translate }} {{archiveDates[0]}}</span>
                <span *ngIf="!showArchiveDates()" class="nav-text">{{ 'T4.PAGE-HEADER.LIVE' | translate }}</span>
              </a>
            </li>

            <li class="dropdown-menu__item clickable">
              <a class="dropdown-link-item" (click)="setArchiveMode(true)">
                <span *ngIf="showArchiveDates()" class="nav-text">{{ 'T4.PAGE-HEADER.ARCHIVEUNTIL' | translate }} {{archiveDates[1]}}</span>
                <span *ngIf="!showArchiveDates()" class="nav-text">{{ 'T4.PAGE-HEADER.ARCHIVE' | translate }}</span>
              </a>
            </li>

          </ul>
        </li>


        <!-- Offcanvas -->

        <!-- Message Center -->
      <div *ngIf="hasNotificationService">
        <li class="dropdown custom-dropdown">

          <button (click)="toggleNotificationCenter()" class="notify-toggler custom-dropdown-toggler">
            <i class="mdi mdi-bell-outline icon"></i>
            <span *ngIf="newNotifications > 0" class="badge badge-xs rounded-circle">{{ newNotifications }}</span>
          </button>

          <div *ngIf="notificationCenterVisible" class="dropdown-notify">
            <ng-container *ngIf="notificationsToDisplay != null && notificationsToDisplay.NotificationsPerType.length == 0; else notifications">

              <div class="notification-center-body">
                <div style="height: 325px;" class="media media-sm p-4 bg-light mb-0">
                  <div class="media-body" style="padding-top: 120px; text-align:center;">
                    <i style="font-size: 22px;" class="fa fa-bell-o" aria-hidden="true"></i>
                    <span class="title mb-0">{{ 'NOTIFICATIONCENTER.NOMESSAGES' | translate }}</span>
                  </div>
                </div>
              </div>

            </ng-container>

            <ng-template #notifications>
              <header>
                <div class="nav nav-underline" id="nav-tab" role="tablist">

                  <a *ngFor="let type of notificationsToDisplay.NotificationsPerType" (click)="selectType(type)" [class.active]="selectedTypeId == type.Id" class="nav-item nav-link"role="tab">
                    {{ type.TranslationCode | translate }} ({{type.Notifications.length}})
                  </a>
                  <!-- <a class="nav-item nav-link active" id="all-tabs" data-toggle="tab" href="#all" role="tab" aria-controls="nav-home" aria-selected="true">All (5)</a>
                  <a class="nav-item nav-link" id="message-tab" data-toggle="tab" href="#message" role="tab" aria-controls="nav-profile" aria-selected="false">Msgs (4)</a>
                  <a class="nav-item nav-link" id="other-tab" data-toggle="tab" href="#other" role="tab" aria-controls="nav-contact" aria-selected="false">Others (3)</a> -->
                </div>
              </header>

              <ng-container *ngFor="let type of notificationsToDisplay.NotificationsPerType">
                <div [class.d-none]="selectedTypeId !== type.Id" class="notification-center-body">
                  <div *ngFor="let notification of type.Notifications" class="media media-sm p-4 bg-light mb-0">

                    <ng-container *ngIf="notification.LongRunningOperationId === null; else LRO">

                      <span style="height: 64px; width: 64px; font-size: 32px; color: #487EBC"  class="mdi mdi-information-outline"></span>
                      <div class="media-body">
                        <a href="#">
                          <span class="title mb-0">{{ notification.TranslationCode | translate}}</span>
                          <span class="discribe">{{ notification.DetailText | replaceOnEmpty }}</span>
                          <span class="time">
                            <time>{{ notification.Created | localDateTime }}</time>...
                          </span>
                        </a>
                      </div>

                    </ng-container>

                    <!-- START OF LRO TEMPLATE -->
                    <ng-template #LRO>

                      <ng-container *ngIf="notification.LROIsPending">
                        <div class="d-flex align-items-center justify-content-center spin-holder" style="height: 64px;">
                          <div class="sk-circle sk-circle-small">
                            <div class="sk-circle1 sk-child"></div>
                            <div class="sk-circle2 sk-child"></div>
                            <div class="sk-circle3 sk-child"></div>
                            <div class="sk-circle4 sk-child"></div>
                            <div class="sk-circle5 sk-child"></div>
                            <div class="sk-circle6 sk-child"></div>
                            <div class="sk-circle7 sk-child"></div>
                            <div class="sk-circle8 sk-child"></div>
                            <div class="sk-circle9 sk-child"></div>
                            <div class="sk-circle10 sk-child"></div>
                            <div class="sk-circle11 sk-child"></div>
                            <div class="sk-circle12 sk-child"></div>
                          </div>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="notification.NotificationStatusCode == 'Succeeded'">
                        <span class="lro-notify lro-done">
                          <i class="fa fa-check-circle" aria-hidden="true"></i>
                        </span>
                      </ng-container>

                      <ng-container *ngIf="notification.NotificationStatusCode == 'Errored'">
                        <span class="lro-notify lro-danger">
                          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                        </span>
                      </ng-container>

                      <ng-container *ngIf="notification.NotificationStatusCode == 'Canceled'">
                        <span class="lro-notify lro-warning">
                          <i class="fa fa-ban" aria-hidden="true"></i>
                        </span>
                      </ng-container>

                      <div class="media-body">

                          <span class="title mb-0">{{ notification.TranslationCode | translate }}</span>
                          <span class="discribe">{{ notification.ProgressPercentage }}% - {{ getDetailText(notification) | replaceOnEmpty | translate }}</span>

                          <div *ngIf="notification.LROIsPending && notification.NotificationStatusCode != 'AwaitingContinuation'">
                            <button (click)="cancelLRO(notification.LongRunningOperationId)">{{ 'LRO.CANCEL' | translate }}</button>
                          </div>
                          <div *ngIf="notification.HasAttachment">
                            <button (click)="downloadAttachment(notification.NotificationId)">{{ 'LRO.DOWNLOAD_ATTACHMENT' | translate }}</button>
                          </div>
                          <div *ngIf="notification.AllowsContinuation">
                            <button (click)="createContinuation(notification.NotificationId)">{{ 'LRO.START-CONTINUATION' | translate }}</button>
                          </div>
                          <div *ngIf="notification.HasAdditionalData">
                            <button (click)="showAdditionalInformation(notification.NotificationId)">{{ 'LRO.SHOWMORE' | translate }}</button>
                          </div>

                      </div>

                    </ng-template>
                  </div>

                </div>
              </ng-container>

              <footer class="border-top dropdown-notify-footer">
                <div class="d-flex justify-content-between align-items-center py-2 px-4">
                  <span>{{ 'NOTIFICATIONCENTER.LASTUPDATED' | translate }}: {{ notificationsToDisplay.RefreshTime  | localTime }}</span>
                </div>
              </footer>
            </ng-template>

          </div>

        </li>
      </div>
        <!-- Message Center -->

        <!-- User Account -->
        <li class="dropdown user-menu">
          <button href="#" class="dropdown-toggle nav-link" data-toggle="dropdown" *ngIf="user" id="user-profile-dropdown">
            <img *ngIf="this.user.ProfileImage" [src]="this.user.ProfileImage" class="user-image rounded-circle" alt="User Image" />
            <span class="d-none d-lg-inline-block">{{ this.user.Fullname }}</span>
          </button>
          <ul class="dropdown-menu dropdown-menu-right clickable">
            <!-- User image -->

            <li class="dropdown-menu__item" *ngIf="showGoToProfile">
              <a class="dropdown-link-item" (click)="goToProfile()">
                <i class="mdi mdi-account-outline"></i>
                <span class="nav-text">{{'T4.PAGE-HEADER.MYPROFILE' | translate}}</span>
              </a>
            </li>

            <li *ngFor="let channel of selectableChannels; first as isFirst; last as isLast"
                [ngClass]="{'dropdown-menu__item--small-first': isFirst, 'dropdown-menu__item--small-last': isLast}">
              <a class="dropdown-link-item" (click)="setChannel(channel.SalesChannelId)">
                <i class="mdi mdi-account-outline"></i>
                <span class="nav-text">{{ channel.Name | excerpt:25}}</span>
              </a>
            </li>

            <li class="dropdown-menu__item dropdown-menu__item--last clickable">
              <a class="dropdown-link-item" (click)="logOff()"><i class="mdi mdi-logout"></i>
                <span class="nav-text">{{'BTN.LOGOFF' | translate}}</span>
              </a>
            </li>

          </ul>
        </li>

      </ul>
    </div>
  </nav>


</header>




<nav aria-label="breadcrumb">
  <ol class="breadcrumb slim-breadcrumb">
    <li *ngFor="let bc of breadCrumbs; let last = last" class="breadcrumb-item" [class.active]="last">

      <ng-container *ngIf="last === true || bc.noNavigate; else hasNav"><i *ngIf='bc.isHome'
          class="fas fa-home"></i>{{bc.label | translate}}</ng-container>

      <ng-template #hasNav>
        <a [routerLink]="bc.url"><i *ngIf='bc.isHome' class="fas fa-home"></i>{{bc.label | translate}}</a>
      </ng-template>

    </li>
  </ol>
</nav>
