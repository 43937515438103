import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationCommunicationService {

  public $notificationAttachmentDownloadRequired = new Subject<number>();
  public $notificationUpdateRequired = new Subject<void>();
  public $notifications = new BehaviorSubject<any>(undefined);
  public $lroCanceled = new Subject<number>();
  public $notificationContinuationTriggered = new Subject<number>();
  public $notificationShowMoreInformation = new Subject<number>();

  cancelLRO(lroId: number) {
    this.$lroCanceled.next(lroId);
  }
  
  downloadAttachment(notificationId: number) {
    this.$notificationAttachmentDownloadRequired.next(notificationId);
  }

  public triggerContinuation(notificationId: number) {
    this.$notificationContinuationTriggered.next(notificationId);
  }

  public setNotifications(notifications: any) {
    this.$notifications.next(notifications);
  }

  public updateNotificationsAndLROS() {
    this.$notificationUpdateRequired.next();
  }

}
