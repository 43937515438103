import {Injectable} from '@angular/core';
import {IrregularPagerData} from '../models/pager/irregular-pager-data';
import {PagerBaseData} from '../models/pager/pager-base-data';
import {PagerData} from '../models/pager/pager-data';

@Injectable()
export class PagerService {

  getPager(totalItems: number, currentPage: number = 0, pageSize: number = 10): PagerData {
    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);

    const pagerData = this.calculatePages(totalPages, currentPage) as PagerData;

    // calculate start and end item indexes
    pagerData.startIndex = (currentPage - 1) * pageSize;
    pagerData.endIndex = Math.min(pagerData.startIndex + pageSize - 1, totalItems - 1);

    pagerData.pageSize = pageSize;

    return pagerData;
  }

  private calculatePages(totalPages: number, currentPage: number): PagerBaseData {
    const pagerData = new PagerBaseData();
    pagerData.totalPages = totalPages;
    pagerData.currentPage = currentPage;
    if (totalPages <= 4) {
      // less than 10 total pages so show all
      pagerData.startPage = 1;
      pagerData.endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 2) {
        pagerData.startPage = 1;
        pagerData.endPage = 4;
      } else if (currentPage + 1 >= totalPages) {
        pagerData.startPage = totalPages - 4;
        pagerData.endPage = totalPages;
      } else {
        pagerData.startPage = currentPage - 2;
        pagerData.endPage = currentPage + 1;
      }
    }

    // create an array of pages to ng-repeat in the pager control
    pagerData.pages = Array.from(Array(pagerData.endPage + 1 - pagerData.startPage), (_, i) => pagerData.startPage + i);

    return pagerData;
  }

  getPagerIrregular(totalPages: number, currentPage: number): IrregularPagerData {
    return this.calculatePages(totalPages, currentPage) as IrregularPagerData;
  }
}
