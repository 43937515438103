import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {LoggingService, ApiDetails, EnvironmentService} from '@tymes4-shared';

@Injectable()
export class AuthGuard implements CanActivate {
  public authToken;
  private configuration: ApiDetails;

  constructor(private router: Router, private logging: LoggingService, private environment: EnvironmentService) {
    this.configuration = environment.loadConfigurations();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (sessionStorage.getItem('jwt') != null) {
      this.logging.log('User is authenticated with token.');
      return true;
    }

    this.logging.log('User not authenticated.');
    this.router.navigateByUrl('error/invalid-login');
    return false;
  }
}
