<div class="mg-t-20 ">

  <ul class="pagination mg-b-0" *ngIf="pager.pages && pager.pages.length > 1">
      <li class="clickable page-item d-none d-sm-block" class.disabled="currentPageNr === 1" *ngIf="currentPageNr != 1">
          <a class="page-link"(click)="setPage(1)" aria-label="First"><i class="fa fa-angle-double-left"></i></a>
      </li>

      <li class="clickable page-item"  [class.active]="currentPageNr === page" *ngFor="let page of pager.pages; let idx = index">
          <a class="page-link" (click)="setPage(page)">{{page}}</a>
      </li>

      <li class="clickable page-item d-none d-sm-block"  *ngIf="currentPageNr != pager.totalPages">
          <a class="page-link" (click)="setPage(pager.totalPages)"><i class="fa fa-angle-double-right"></i></a>
      </li>

  </ul>

</div>
