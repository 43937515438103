import { FanclubContactsComponent } from './../../../views/my-fanclub/fanclub-contacts/fanclub-contacts.component';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { HttpLoaderService, KeyValuePair, ConfirmService } from '@tymes4-shared';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from "@angular/core";
import * as moment from 'moment';
import { CrossSellProductService } from '../../api';

@Component({
    selector: 'fan-edit-event-product',
    templateUrl: './edit-event-product.component.html'
  })

  export class EditEventProductComponent implements OnInit {

    public eventId: number;
    public product: any;
    public isNew: boolean;

    public form: FormGroup;
    public saving: boolean = false;

    public readonly: boolean;

    validationMessages = { }

    private requiredFields: KeyValuePair<boolean> = null;

    isRequiredField(field: string): boolean {
        if (this.readonly) return false;

        if (this.requiredFields === null)
          this.requiredFields = {};

        if (this.requiredFields[field] === null || this.requiredFields[field] === undefined) {
          const form = this.form;
          const formField = form.get(field);
          if (!formField.validator) {
            return false;
          }

          const validator = formField.validator({} as AbstractControl);
          this.requiredFields[field] = validator && validator.required;

          formField.validator(formField);
        }
        return this.requiredFields[field];
      }

    constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
      public dialogRef: MatDialogRef<EditEventProductComponent>,
      private productService : CrossSellProductService,
      private loader: HttpLoaderService,
      private confirm: ConfirmService,
      private translate: TranslateService,
      public datePipe: DatePipe) {}

    ngOnInit()
    {
      this.isNew = this.passedData.isNew;

      if (this.isNew)
      {
        this.eventId = this.passedData.eventId;
        this.product = {};
        this.product.OnSaleFrom = null;
        this.product.OnSaleTill = null;
        this.product.FantastixOnSaleFrom = this.passedData.FantastixOnSale.From;
        this.product.FantastixOnSaleTill = this.passedData.FantastixOnSale.Till;
        this.product.SoldCount = 0;
        this.readonly = false;
      }
      else
      {
        this.product = this.passedData.payload;
        this.readonly = !this.product.IsFantastixManageable;
      }

      this.form = new FormGroup({
        EventCrossSellProductVariantId: new FormControl(null),
        CrossSellProductName: new FormControl({ value: '', disabled: this.readonly}, [Validators.required, Validators.maxLength(499)]),
        AmountInVat: new FormControl({ value: '', disabled: this.readonly}, [Validators.required, Validators.max(Number.MAX_SAFE_INTEGER)]),
        CrossSellProductDescription: new FormControl({ value: '', disabled: this.readonly}, [Validators.required, Validators.maxLength(999)]),
        CurrentStockCount: new FormControl({ value: '', disabled: this.readonly}, [Validators.required, Validators.max(Number.MAX_SAFE_INTEGER)]),
        OnSaleFrom: new FormControl({ value: this.product.OnSaleFrom, disabled: this.readonly}),
        OnSaleTill: new FormControl({ value: this.product.OnSaleTill, disabled: this.readonly}),
       });

       this.form.patchValue(this.product);
    }

    submit() {
      this.saving = true;
      this.loader.open();

      const data = this.form.value;
      if (this.isNew)
      {
        this.productService.createCrossSellProduct(this.eventId, data).subscribe((result: number) => {
          this.saving = false;
          this.loader.close();
          this.dialogRef.close(true);
        })
      }
      else
      {
        this.productService.updateCrossSellProduct(data).subscribe((result: string) => {
          this.saving = false;
          this.loader.close();
          if (result != null && result.length > 0)
          {
            //validation failed, show result message;
            this.confirm.okOnlyConfirm(this.translate.instant('FAN.EVENTPRODUCTS.DIALOG.EDIT.FAILED') , result);
          }
          else
          {
            this.dialogRef.close(true);
          }
        })
      }
    }


  }
